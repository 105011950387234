/* eslint-disable consistent-return */
import {
  cloneElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { styled } from 'Theme/stitches.config';
import { timings, animation } from 'DesignSystem/Animations/animation';
import ReactDOM from 'react-dom';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { ModalFooterProps } from './ModalFooter';
import NewModalOverlay from './ModalOverlay';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import ModalHeader from './ModalHeader';

export interface NewModalProps {
  children?: JSX.Element;
  title?: string;
  stepBackAction?: () => void;
  modalFooterComponent?: ReactElement<ModalFooterProps>;
  noContentPadding?: boolean;
}

const FOCUSABLE_ELEMENTS =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const Modal = ({
  children,
  title,
  modalFooterComponent,
  stepBackAction,
  noContentPadding,
}: NewModalProps) => {
  const { modalState, toggleModal } = useUiState();
  const { display: displayModal } = modalState;
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalFooterHeight, setModalFooterHeight] = useState<number>(0);
  // Needed to trigger modal in storybook (Modal.stories.tsx)
  const [modalMounted, setModalMounted] = useState<boolean>(false);

  const getFocusableElements = useCallback(
    (parentElement: HTMLElement): HTMLElement[] => {
      const elements =
        parentElement.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS);
      const focusable: HTMLElement[] = [];
      elements.forEach(function (el) {
        focusable.push(el);
      });
      return focusable.filter(
        (el: HTMLElement) => !(el as HTMLInputElement).disabled
      );
    },
    [children]
  );

  useEffect(() => {
    setModalMounted(true);
    return () => {
      setModalMounted(false);
    };
  }, []);

  useEffect(() => {
    if (canUseDOM()) {
      // Prevent page from scrolling when modal open
      document.body.style.overflow = displayModal ? 'hidden' : 'unset';
    }

    if (modalRef && canUseDOM()) {
      const handleKeyPress = (e: KeyboardEvent) => {
        const isEscapePressed = e.key === 'Escape';
        if (isEscapePressed) {
          toggleModal(false);
          return;
        }
        const isTabPressed = e.key === 'Tab';
        if (!isTabPressed) {
          return;
        }

        const modalContent = modalRef.current;
        if (modalContent) {
          const firstFocusableElement =
            modalContent.querySelectorAll(FOCUSABLE_ELEMENTS)[0];
          const focusableContent = getFocusableElements(modalContent);
          const lastFocusableElement = focusableContent.at(-1);
          if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
              (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
              e.preventDefault();
            }
          } else {
            // if tab key is pressed
            if (document.activeElement === lastFocusableElement) {
              // if focused has reached to last focusable element then focus first focusable element after pressing tab
              (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
              e.preventDefault();
            }
          }
        }
      };
      window.addEventListener('keydown', handleKeyPress);
      return () => window.removeEventListener('keydown', handleKeyPress);
    }
  }, [displayModal]);

  const getModalFooterHeight = (height: number | undefined) => {
    // used to define modalbody height
    if (height) setModalFooterHeight(height);
  };

  // Need to clone the element and add the additional props using React.cloneElement
  // in this case the getModalFooterHeight prop since we pass the ModalFooter as ReactElement<ModalFooterProps> from UiStateContext
  const ClonedModalFooterComponent = modalFooterComponent
    ? cloneElement(modalFooterComponent, {
        getModalFooterHeight: getModalFooterHeight,
      })
    : null;

  if (canUseDOM() && modalMounted) {
    // createPortal lets you render some children into a different part of the DOM (in this case last child of div #root-content).
    const rootDOMElement = document.querySelector<Element>('#root-content');

    return ReactDOM.createPortal(
      <>
        <Container isVisible={displayModal} role="dialog" aria-label={title}>
          <Content ref={modalRef} isVisible={displayModal}>
            <ModalHeader
              closeModal={() => toggleModal(false)}
              displayModal={displayModal}
              modalHeaderContent={{
                title: title,
                stepBackAction: stepBackAction,
              }}
            />

            <Body
              noContentPadding={noContentPadding}
              footerVisible={modalFooterComponent ? true : false}
              // ModalBody height = 100% - (ModalContainer 'top' Property - ModalHeader 'height' property) - Modal footer height dynamic
              css={{
                height: `calc(100% - 128px - ${modalFooterHeight}px)`,
                '@bpMin721': {
                  height: `calc(100% - 144px - ${modalFooterHeight}px)`,
                },
                '@bpMin961': {
                  height: `calc(100% - 80px - ${modalFooterHeight}px)`,
                },
              }}
            >
              {children}
            </Body>

            {ClonedModalFooterComponent && ClonedModalFooterComponent}
          </Content>
        </Container>

        <NewModalOverlay
          displayModal={displayModal}
          closeModal={() => toggleModal(false)}
        />
      </>,
      rootDOMElement as Element
    );
  }

  return null;
};

export default Modal;

const Container = styled('div', {
  position: 'fixed',
  visibility: 'hidden',
  scrollbarWidth: 'none',
  top: 0,
  right: 0,
  h: '100%',
  w: '100%',
  opacity: 1,
  zIndex: '$zIndices$Modal',
  transition: `all ${timings.threeTenths} ${animation.easeOut}`,
  variants: {
    isVisible: {
      true: {
        visibility: 'initial',
        t: '$s300',
        '@bpMin721': {
          t: '$s400',
        },
        '@bpMin961': {
          t: 0,
          w: '480px',
        },
      },
    },
  },
});

const Content = styled('div', {
  overflow: 'auto',
  position: 'absolute',
  b: 0,
  w: '100%',
  h: 0,
  p: 0,
  opacity: 1,
  ml: 'auto',
  borderLeft: '$borders$default solid $modalContentBorder',
  backgroundColor: '$surface',
  transition: `height ${timings.threeTenths} ${animation.easeOut}`,
  borderRadius: '8px 8px 0px 0px',
  '@bpMin961': {
    position: 'relative',
    transition: `all ${timings.threeTenths} ${animation.easeOut}`,
    h: '100%',
    w: 0,
    transform: 'translateX(300%)',
    borderRadius: 0,
  },
  variants: {
    isVisible: {
      true: {
        h: '100%',
        '@bpMin961': {
          w: '480px',
          transform: 'translateX(0)',
        },
      },
    },
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});

const Body = styled('div', {
  backgroundColor: '$surface',
  pr: '$s150',
  pb: '$s200',
  pl: '$s150',
  mt: '$s500',
  overflowY: 'auto',
  overflowX: 'hidden',
  variants: {
    noContentPadding: {
      true: {
        pl: 0,
        pr: 0,
      },
    },
    footerVisible: {
      false: {
        height: 'calc(100% - $s500)',
      },
    },
  },
});
