import { FilterMessage } from '../filterMessages';
import {
  withMutateUrl,
  parseDataToFilter,
} from './filterReducerHelperFunctions';
import { FilterState } from './filterState';

export const filterReducer = (
  state: FilterState,
  message: FilterMessage
): FilterState => {
  switch (message.type) {
    case 'onClear':
      return withMutateUrl(
        {
          ...state,
          query: '',
          multiSelectFilters: new Map(),
          sorterFilter: state.initSorterFilter,
          queryParams: [],
          selectedModel: '',
          data: null,
        },
        true
      );

    case 'setSorterFilter':
      return withMutateUrl({ ...state, sorterFilter: message.value });
    case 'setMultiSelectFilters':
      return withMutateUrl({
        ...state,
        multiSelectFilters: message.selectFilters,
      });

    case 'setQuery':
      return withMutateUrl({
        ...state,
        query: message.value,
      });
    case 'setSorterFilterByText':
      if (!state.data) return state;
      return withMutateUrl({
        ...state,
        sorterFilter:
          state.data.sorters.find(
            (element) => element.text === message.value
          ) || state.sorterFilter,
      });

    case 'setFacets':
      return parseDataToFilter(state, {
        facets: message.facets,
        sorters: message.sorters,
      });

    default:
      return state;
  }
};
