import { useEffect, useState } from 'react';

import ReporterModal from '../DesignComponents/Organisms/Modal/ReporterModal/ReporterModal';
import {
  EventDispatcher,
  ON_KEX_ERROR,
} from '../Shared/Common/EventDispatcher';
import { useKexLoadingCircle } from './KexLoadingCircle';

function KexReporterOverlay() {
  const [errorCount, setErrorCount] = useState(0);
  const [, loadingDispatch] = useKexLoadingCircle();

  const onError = () => {
    setErrorCount(1);
  };

  const onClose = () => {
    setErrorCount(0);

    loadingDispatch &&
      loadingDispatch({
        type: 'removeAll',
        from: '',
      });
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_KEX_ERROR, onError);

    return () => {
      EventDispatcher.unsubscribe(ON_KEX_ERROR, onError);
    };
  });

  return <>{errorCount !== 0 && <ReporterModal onClose={onClose} />}</>;
}

export default KexReporterOverlay;
