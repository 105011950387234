import { CSS } from '@stitches/react';
import { useMemo } from 'react';
import { styled } from 'Theme/stitches.config';
import { StyledBodyText } from './BaseBodyTextStyle';

type ElementType = Pick<JSX.IntrinsicElements, 'span' | 'p'>;

type BodyTextProps = {
  children: React.ReactNode;
  size?: 's' | 'm' | 'l' | 'xl';
  weight?: 'bold' | 'semiBold' | 'regular';
  color?: 'primary' | 'inverse' | 'subtle' | 'oninteractive';
  tag?: keyof ElementType;
  css?: CSS;
};

const BodyText = ({
  children,
  size = 'm',
  tag = 'p',
  weight = 'regular',
  color = 'primary',
  css,
}: BodyTextProps) => {
  const BodyText = useMemo(() => createStyledElement(tag), [tag]);

  return (
    <BodyText size={size} weight={weight} color={color} css={css}>
      {children}
    </BodyText>
  );
};

const createStyledElement = (tag: keyof ElementType) => {
  return styled(tag, StyledBodyText);
};

export default BodyText;
