import { useEffect } from 'react';
import { webStorageHelper } from 'Shared/Common/Helpers';
import { IS_SERVER_CONTEXT } from 'Shared/Configs/EnvConfig';

import { KexNavigateEventStateType, KexNavigateType } from './KexRouter';

export const addScrollHistory = () => {
  /*
   * if current history does not contain any id, add it.
   */
  try {
    if (window.history.state === null) {
      window.history.replaceState(
        { id: new Date().valueOf() },
        document.title,
        window.location.href
      );
    }

    const scrolls = scrollHistory && scrollHistory.get();
    scrolls[window.history.state.id] =
      window.pageYOffset || document.documentElement.scrollTop;
    scrollHistory && scrolls && scrollHistory.set(scrolls);
  } catch {
    //Do nothing
  }
};

const scrollHistory =
  !IS_SERVER_CONTEXT &&
  webStorageHelper({
    type: 'sessionStorage',
    storageKey: 'scrollHistory',
    defaultValue: {},
  });

const pushState = (responseUrl: string, pageTitle: string) => {
  addScrollHistory();
  const state = { id: new Date().valueOf() };

  window.history.pushState(state, pageTitle, responseUrl);
};

const replaceState = (responseUrl: string, pageTitle: string) => {
  if (window.location.href !== responseUrl) {
    window.history.replaceState(window.history.state, pageTitle, responseUrl);
  }
};

export const updateScrollPosition = (
  eventState: KexNavigateEventStateType,
  responseUrl: string,
  pageTitle: string
) => {
  if (!eventState) return;
  //if user navigated through links in page, push link to browser history
  if (eventState.clicked) {
    pushState(responseUrl, pageTitle);
  } else {
    replaceState(responseUrl, pageTitle);
  }

  if (eventState.clicked) {
    //if user navigated through links in page, scroll to top
    window.scrollTo(0, 0);
  } else {
    /*
     * User has used browser's back or forward function
     *
     * if - use saved scroll pos in session storage
     * else - fallback to top
     */
    if (eventState && eventState.id !== undefined) {
      const scrolls = scrollHistory && scrollHistory.get();
      if (scrolls && scrolls[eventState.id] !== undefined) {
        const id = scrolls[eventState.id];
        const scrollPos = window.scrollY || document.documentElement.scrollTop;

        if (scrollPos !== id) {
          setTimeout(() => {
            window.scrollTo(0, id);
          }, 500);
        } else {
          setTimeout(() => window.scrollTo(0, id), 0);
        }
      }
    } else {
      window.scrollTo(0, 0);
    }
  }
  window.focus();
};

export const useSaveScrollPosition = (kexNavigate: KexNavigateType) => {
  useEffect(() => {
    let timeOutFn: number;
    let touchMoved = false;
    const isTouchDevice = 'ontouchstart' in window;

    /*
     * workaround for touchdevices (mainly phones) that do not have 'scrollRestoration' in window.history
     */
    if (isTouchDevice && !('scrollRestoration' in window.history)) {
      document.body.addEventListener(
        'touchstart',
        () => {
          touchMoved = true;
        },
        { passive: true }
      );

      window.addEventListener(
        'scroll',
        () => {
          if (touchMoved) {
            addScrollHistory();
            clearTimeout(timeOutFn);
            timeOutFn = window.setTimeout(() => {
              touchMoved = false;
            }, 50);
          }
        },
        { passive: true }
      );
    }

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
      window.addEventListener('scroll', addScrollHistory, { passive: true });
    } else {
      /*
       * workaround for browsers that do not have 'scrollRestoration' in window.history
       */
      ['blur', 'mouseout', 'beforeunload'].forEach((e) =>
        window.addEventListener(e, addScrollHistory)
      );
    }

    window.addEventListener('popstate', (e) => {
      kexNavigate(window.location.href, e);
    });
  }, [kexNavigate]);
};
