// Documentation for stiches --> https://stitches.dev/docs/styling
import { createStitches } from '@stitches/react';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { utils } from 'Theme/Settings/utils';
import sizes from 'Theme/Settings/sizes';
import tokens from './Settings/tokens';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    sizes,
    ...tokens,
    zIndices: {
      Notification: 9,
      Ribbon: 9,
      Header: 10,
      QuickSearch: 12,
      Flyout: 10,
      DropDown: 9,
      QuantityDropDown: 10,
      FlyoutLower: 5,
      Cart: 10,
      CartMobile: 4,
      ConfiguratorSummary: 4,
      Modal: 20,
      ProductCardSecondary: 6,
      ProductCardMain: 7,
      ProductCardTag: 8,
      ProductCardFavorite: 8,
      Minicart: 9,
      Tooltip: 40,
      ModalOverlay: 19,
      OverlayMegaMenu: 9,
      pageLoader: 990,
      errorModal: 1000,
    },
    transitions: {
      '200': '200ms',
      '300': '300ms',
      '400': '400ms',
      '500': '500ms',
      '600': '600ms',
      '700': '700ms',
      '1000': '1000ms',
      '1500': '1500ms',
      '5000': '5000ms',
    },
  },
  utils,
  media: mediaQueryTypes,
});
