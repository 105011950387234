export const StyledBodyText = {
  color: '$onSurface',

  variants: {
    weight: {
      bold: {
        fontFamily: 'fontBold',
      },
      semiBold: {
        fontFamily: 'fontSemiBold',
      },
      regular: {
        fontFamily: 'fontRegular',
      },
    },
    color: {
      primary: {
        color: '$onSurface',
      },
      inverse: {
        color: '$onInverse',
      },
      subtle: {
        color: '$onSurfaceSubtle',
      },
      oninteractive: {
        color: '$onInteractivePrimary',
      },
    },
    size: {
      s: {
        fontSize: '$fontSize50',
        lineHeight: '$lh16',
      },
      m: {
        fontSize: '$fontSize75',
        lineHeight: '$lh24',
      },
      l: {
        fontSize: '$fontSize200',
        lineHeight: '$lh28',
      },
      xl: {
        fontSize: '$fontSize300',
        lineHeight: '$lh32',
      },
    },
  },
};
