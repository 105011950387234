import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Arrow({ size, color, title }: ComponentProps) {
  return (
    <>
      <BaseSvgStyle
        size={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title && <title>{title}</title>}
        <BaseIconPathStyle
          d="M23.1426 12H0.856934"
          stroke={color}
          strokeRound
        />
        <BaseIconPathStyle
          d="M6.85693 6L0.856934 12L6.85693 18"
          stroke={color}
          strokeRound
        />
      </BaseSvgStyle>
    </>
  );
}

export default Arrow;
