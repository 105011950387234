import React from 'react';
import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';
import { StitchesVariantProps } from 'Shared/Types/types';

type StitchesVariants = StitchesVariantProps<typeof Container>;

type PropType = {
  width?: StitchesVariants['width'];
  children: React.ReactNode;
  noPadding?: boolean;
  css?: CSS;
};

function ContentContainer({
  width = 'content',
  children,
  noPadding,
  css,
}: PropType) {
  return (
    <Container width={width} css={css} noPadding={noPadding}>
      {children}
    </Container>
  );
}

export default ContentContainer;

const Container = styled('div', {
  w: '100%',
  mx: 'auto',
  py: 0,
  px: 4,
  variants: {
    width: {
      content: {
        px: '$s100',
        maxW: '$screenMaxWidth',
        '@bpMin376': {
          px: '$s100',
        },
        '@bpMin721': {
          px: '$s100',
        },
        '@bpMin1025': {
          px: '$s400',
        },
      },
      narrow: {
        maxW: '520px',
      },
      full: {
        maxW: 'auto',
      },
    },
    noPadding: {
      true: {
        px: 0,
      },
    },
  },
});
