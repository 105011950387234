import { styled } from 'Theme/stitches.config';
import { timings } from 'DesignSystem/Animations/animation';
import {
  animationLoadingStrokeDash,
  animationRotation,
} from 'DesignSystem/Animations/keyframes';

export const BaseIconStyle = styled('svg', {
  wh: 48,
  variants: {
    isLoading: {
      true: {
        animation: `${animationRotation}`,
        animationDuration: timings.threeFifths,
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      },
    },
  },
});

export const BaseIconPathStyle = styled('path', {
  variants: {
    color: {
      primary: {
        stroke: '$onSurface',
      },
    },
  },
});

export const BaseIconCircleStyle = styled('circle', {
  strokeLinecap: 'round',
  variants: {
    color: {
      primary: {
        stroke: '$onSurface',
      },
    },
    isLoading: {
      true: {
        animation: `${animationLoadingStrokeDash}`,
        animationDuration: timings.oneAndAHalf,
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});

export const BaseIconCircleStyleBackground = styled('circle', {
  strokeLinecap: 'round',
  stroke: '$surface',
});
