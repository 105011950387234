import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import LoadingPageType from 'Cms/Pages/LoadingPageType/LoadingPageType';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

import loadable from '@loadable/component';
import { useEffect } from 'react';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

/* not possible to use full dynamic imports, in server context it wont be able to locate chunks */
const loadablePages = {
  StartPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Cms/Pages/StartPage/StartPage')
  ),
  StandardPage: loadable(
    () =>
      import(/* webpackPrefetch: true */ 'Cms/Pages/StandardPage/StandardPage')
  ),
  CampaignCollectionPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Cms/Pages/CampaignCollectionPage/CampaignCollectionPage'
      )
  ),
  ArticleProductPage: loadable(
    // Product Page
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/ArticleProductPage'
      )
  ),
  GiftCardProductPage: loadable(
    // Product Page
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/GiftCardProductPage'
      )
  ),
  SubscriptionOfferProductPage: loadable(
    // Product Page
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/SubscriptionOfferProductPage'
      )
  ),
  MagazineOverviewPage: loadable(
    // Magazine Overview Page
    () =>
      import(
        /* webpackPrefetch: true */ 'Cms/Pages/MagazineOverviewPage/MagazineOverviewPage'
      )
  ),
  MagazinePage: loadable(
    // Magazine Page
    () =>
      import(/* webpackPrefetch: true */ 'Cms/Pages/MagazinePage/MagazinePage')
  ),
  ArticleCategoryPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/CategoryPage/ArticleCategoryPage' // TODO: Egmont - Add template for ArticleCategoryPage
      )
  ),
  BlockPreviewPage: loadable(() => import('./BlockPreviewPage')),
  BrandPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Cms/Pages/BrandPage/BrandPage')
  ),
  CheckoutPage: loadable(
    () =>
      import(/* webpackPrefetch: true */ 'Commerce/Pages/Checkout/CheckoutPage')
  ),
  OrderConfirmationPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/OrderConfirmation/OrderConfirmationPage'
      )
  ),
  SearchPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Cms/Pages/SearchPage/SearchPage')
  ),
  GiftCardCodePage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Commerce/Pages/GiftCardCodePage/GiftCardCodePage'
      )
  ),
  BookClubPage: loadable(
    () =>
      import(/* webpackPrefetch: true */ 'Cms/Pages/BookClubPage/BookClubPage')
  ),
  StorybookPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Cms/Pages/StorybookPage/StorybookPage'
      )
  ),
  NotFoundPage: loadable(() => import('Cms/Pages/NotFoundPage/NotFoundPage')),
  ErrorPage: loadable(() => import('Cms/Pages/ErrorPage/ErrorPage')),
};

type KexPageType = keyof typeof loadablePages;

const IsPageTypeKey = (x: string): x is KexPageType =>
  Object.keys(loadablePages).includes(x);

const excludePage = ['CheckoutPage', 'NotFoundPage'];

const KexPage = () => {
  const { pageType } = useCurrentPage<PageModelBase>();

  const currentUrl = canUseDOM() && window.location.href;

  useEffect(() => {
    // store window history so we can redirect back from CheckoutPage
    // user first visit from e.g bookmark we don't have access to prev url in javascript api which is why we need sessionStorage
    if (!excludePage.includes(pageType)) {
      sessionStorage.setItem('url', window.location.href);
    }
  }, [currentUrl]);

  if (!IsPageTypeKey(pageType)) {
    return <></>;
  }

  const LoadablePage = loadablePages[pageType];
  return <LoadablePage fallback={<LoadingPageType />} />;
};

export default KexPage;
