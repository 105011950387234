import { keyframes } from 'Theme/stitches.config';

export const animationOpacity = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

export const animationFrames = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '20%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.2,
  },
});

export const animationLoader = keyframes({
  '0%': {
    transform: 'translate(0,0)',
    'animation-timing-function': 'cubic-bezier(0.45,0,0.9,0.55)',
  },
  '50%': {
    transform: 'translate(0,120px)',
    'animation-timing-function': 'cubic-bezier(0,0.45,0.55,0.9)',
  },
  '100%': {
    transform: 'translate(0,0)',
    'animation-timing-function': 'cubic-bezier(0.45,0,0.9,0.55)',
  },
});

export const animationRotation = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export const animationLoadingStrokeDash = keyframes({
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: '0',
    stroke: '$decorationSubtle',
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-35',
    stroke: '$onSurface',
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-124',
    stroke: '$decorationSubtle',
  },
});

export const animationTranslateInDesktop = keyframes({
  '0%': {
    transform: 'translateY(-68px)',
  },
  '30%': {
    transform: 'translateY(68px)',
  },
  '50%': {
    transform: 'translateY(68px)',
  },
  '80%': {
    transform: 'translateY(68px)',
  },
  '100%': {
    transform: 'translateY(-68px)',
  },
});

export const animationTranslateInMobile = keyframes({
  '0%': {
    transform: 'translateY(101vh)',
  },
  '30%': {
    transform: 'translateY(calc(100vh - 170px))',
  },
  '50%': {
    transform: 'translateY(calc(100vh - 170px))',
  },
  '80%': {
    transform: 'translateY(calc(100vh - 170px))',
  },
  '100%': {
    transform: 'translateY(101vh)',
  },
});

export const slideFromLeftAnimation = keyframes({
  '0%': {
    transform: 'translateX(0)',
  },
  '100%': {
    transform: 'translateX(-100%)',
  },
});

export const slideFromRightAnimation = keyframes({
  '0%': {
    transform: 'translateX(0%)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
});
