import { ModalUiState, QuickSearchUiState } from './UiStateProvider';
import ShippingCountryModel from 'Models/Shipping/ShippingCountryModel.interface';

type UiStateType = {
  modalState: ModalUiState;
  quickSearchState: QuickSearchUiState;
  country: ShippingCountryModel;
};

type UiStateActionType =
  | {
      type: 'toggleModal';
      payload: {
        modalState?: ModalUiState;
      };
    }
  | {
      type: 'focusQuickSearch';
      payload: {
        quickSearchState?: QuickSearchUiState;
      };
    }
  | {
      type: 'setCountry';
      payload: {
        country?: ShippingCountryModel;
      };
    };

const uiStateReducer = (
  state: UiStateType,
  action: UiStateActionType
): UiStateType => {
  switch (action.type) {
    case 'toggleModal': {
      if (!action.payload.modalState) return { ...state };

      //restore focus to opener element when modal is closed
      if (
        !action.payload.modalState.display &&
        state.modalState.openerElement
      ) {
        state.modalState.openerElement.focus();
      }

      return {
        ...state,
        modalState: {
          display: action.payload.modalState.display,
          viewId: action.payload.modalState.viewId,
          modalTitle: action.payload.modalState.modalTitle,
          modalContentComponent:
            action.payload.modalState.modalContentComponent,
          modalFooterComponent: action.payload.modalState.modalFooterComponent,
          openerElement: action.payload.modalState.openerElement,
        },
      };
    }
    case 'focusQuickSearch': {
      if (!action.payload.quickSearchState) return { ...state };

      return {
        ...state,
        quickSearchState: {
          isFocused: action.payload.quickSearchState.isFocused,
        },
      };
    }
    case 'setCountry': {
      if (!action.payload.country) return { ...state };

      return {
        ...state,
        country: action.payload.country,
      };
    }

    default:
      throw new Error(`No case for type: ${action} found in uiStateReducer.`);
  }
};

export default uiStateReducer;
