// Documentation for stiches --> https://stitches.dev/docs/styling
import { createStitches } from '@stitches/react';
import colors from './dark.colors';

const { createTheme } = createStitches({});

const dark = createTheme({
  colors,
});

export default dark;
