import AppInitModel from 'Models/App/AppInitModel.interface';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import { AppSettingsProvider } from 'Shared/Providers/AppSettingsProvider';
import { TranslationProvider } from 'Shared/Providers/TranslationProvider';
import { UserContextProvider } from 'Shared/Providers/UserContextProvider';
import { styled } from 'Theme/stitches.config';
import loadable from '@loadable/component';
import useCurrentPage from '../Features/Shared/Hooks/useCurrentPage';
import { KexLoadingCircle } from './Kex/KexLoadingCircle';
import KexPage from './Kex/KexPage';
import KexReporterOverlay from './Kex/KexReporterOverlay';
import { KexRouter } from './Kex/KexRouter/KexRouter';
import Breadcrumb from './DesignComponents/Molecules/Breadcrumb/Breadcrumb';
import { Header } from './DesignComponents/Organisms/Header/Header';
import ErrorBoundary from './Shared/ErrorBoundary/ErrorBoundary';
import globalStyles from './DesignSystem/Styles/global.stitches';
import { ThemeProvider } from './Theme/ThemeProvider';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import KexNotificationOverlay from './DesignComponents/Molecules/Notifications/BasicNotification';
import { UiStateProvider } from 'Shared/Providers/UiState/UiStateProvider';
import PageMetaData from 'Shared/SEO/PageMetaData';
import { HelmetData, HelmetProvider } from 'react-helmet-async';
import Footer from 'DesignComponents/Organisms/Footer/Footer';
import { FilterProvider } from 'Shared/Providers/FilterProvider';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import GenericModal from 'DesignComponents/Organisms/Modal/GenericModal/GenericModal';
import { SrOnlyStyle } from 'DesignSystem/Accessibility/Utils';
import { excludedPageTypes } from 'Shared/Constants/common';

type PropType = {
  appInitData: AppInitModel;
};

const NotFoundPage = loadable(
  () =>
    import(/* webpackPrefetch: true */ 'Cms/Pages/NotFoundPage/NotFoundPage')
);

const excludeBreadcrumb = [
  'StartPage',
  'CheckoutPage',
  'OrderConfirmationPage',
  'SearchPage',
  'MagazineOverviewPage',
  'MagazinePage',
  'GiftCardCodePage',
  'CampaignCollectionPage',
];

const excludeFooter: string | string[] = [];

const helmetContext = new HelmetData({});

const Content = ({ appInitData }: PropType) => {
  const { pageType, breadcrumb } = useCurrentPage<PageModelBase>();

  return (
    <HelmetProvider context={helmetContext}>
      <>
        <PageMetaData />
        {pageType === 'BlockPreviewPage' || pageType === 'StorybookPage' ? (
          <KexPage />
        ) : (
          <>
            {pageType && !excludedPageTypes.includes(pageType) && (
              <Header initData={appInitData.header} />
            )}

            <main id="root-content">
              {pageType && !excludeBreadcrumb.includes(pageType) && (
                <ContentContainer>
                  <Breadcrumb
                    breadcrumbs={breadcrumb}
                    languageRoute={appInitData.appSettings.languageRoute}
                    siteName={appInitData.appSettings.siteName}
                  />
                </ContentContainer>
              )}
              <SRNotifier aria-live="assertive" id="srNotifier"></SRNotifier>
              <KexPage />
            </main>
            {pageType && !excludeFooter.includes(pageType) && (
              <Footer initData={appInitData.footer} />
            )}
            <KexNotificationOverlay />
          </>
        )}
      </>
    </HelmetProvider>
  );
};

const SRNotifier = styled('div', {
  ...SrOnlyStyle,
});

function App({ appInitData }: PropType) {
  globalStyles();

  return (
    <AppSettingsProvider data={appInitData.appSettings}>
      <TranslationProvider data={appInitData.appSettings.translations}>
        <ThemeProvider>
          <UserContextProvider data={appInitData.userState}>
            <FilterProvider
              urlSearchString={(canUseDOM() && window?.location.search) || ''}
              noQuery={true}
            >
              <UiStateProvider>
                <ErrorBoundary FallbackComponent={() => <NotFoundPage />}>
                  <KexLoadingCircle>
                    <KexReporterOverlay />
                    <KexRouter
                      appInitData={appInitData}
                      initUrl={appInitData.initUrl}
                    >
                      <Content appInitData={appInitData} />
                      <GenericModal />
                    </KexRouter>
                  </KexLoadingCircle>
                </ErrorBoundary>
              </UiStateProvider>
            </FilterProvider>
          </UserContextProvider>
        </ThemeProvider>
      </TranslationProvider>
    </AppSettingsProvider>
  );
}

export default App;
