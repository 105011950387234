import Modal from 'DesignComponents/Organisms/Modal/Modal';
import { useState, useEffect } from 'react';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';

const GenericModal = () => {
  const [View, setView] = useState<JSX.Element>();
  const { modalState } = useUiState();
  const { viewId, modalContentComponent, modalFooterComponent, modalTitle } =
    modalState;

  useEffect(() => {
    if (viewId && modalContentComponent?.component) {
      setView(modalContentComponent.component);
    }
  }, [modalContentComponent]);

  return (
    <Modal
      modalFooterComponent={modalFooterComponent}
      noContentPadding={modalContentComponent?.noContentPadding}
      title={modalTitle}
    >
      {View && View}
    </Modal>
  );
};

export default GenericModal;
