import { EventDispatcher, ON_KEX_ERROR } from 'Shared/Common/EventDispatcher';

type Options = {
  body?: BodyInit;
  headers?: {
    'Content-Type': string;
    credentials: string;
  };
  method?: string;
};

const defaultOptions: Options = {
  headers: {
    'Content-Type': 'application/json',
    credentials: 'same-origin',
  },
};

export const fetcherSwr = (url: string, options?: Options) =>
  fetch(url, {
    ...defaultOptions,
    ...(options || {}),
  })
    .then((res) => {
      if (res.ok || res.status === 404) {
        return res.json();
      }
      throw new Error(`API: ${res.statusText}`);
    })
    .then((response) => {
      if ('data' in response) return response.data;
      if ('userState' in response) return response.userState;

      return response;
    })
    .catch((err) => {
      console.error(err);
      EventDispatcher.dispatch(ON_KEX_ERROR, err);
    });

export const fetcherSwrPost = (url: string, body: string | object) =>
  fetcherSwr(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
