export type ColorType = keyof typeof colors;

const colors = {
  //Universal
  white: '#',

  //Base - Used for static backgrounds and borders
  surface: '#1B1C1A',
  surfaceVariant: '#454844',
  decorationBackground: '#454844',
  decorationSubtle: '#A5ADA4',
  decorationEmphasized: '#8F918D',
  inverse: '#C9C6C2',
  slateGray: '#829997',

  //Text and icon
  onSurface: '#E4E2DF',
  onSurfaceSubtle: '#C5C7C2',
  onSurfaceVariant: '#C5C7C2',
  onInteractivePrimary: '#454744',
  onInteractiveEmphasized: '#153720',
  onInteractiveSecondary: '#E1E3DE',
  onInverse: '#1B1C1A',

  //Semantic
  semanticError: '#FFB4AB',
  semanticInformative: '#9ACBFF',
  semanticSuccess: '#82D996',
  semanticWarning: '#FFB688',

  //Elevation
  elevateOne:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #1B1C1A',

  overlayOne: '#FFFFFFB3',
  underlayOne: '#00000066',
  //Interactive primary background
  interactivePrimaryBackgroundDefault: '#C9C6C2',
  interactivePrimaryBackgroundHover:
    'linear-gradient(0deg, rgba(27, 28, 26, 0.08), rgba(27, 28, 26, 0.08)), #FEFCF9',
  interactivePrimaryBackgroundPressed:
    'linear-gradient(0deg, rgba(27, 28, 26, 0.12), rgba(27, 28, 26, 0.12)), #FEFCF9',

  //Interactive Secondary background
  interactiveSecondaryBackgroundDefault: '#E1E3DE',
  interactiveSecondaryBackgroundHover:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #1B1C1A',
  interactiveSecondaryBackgroundPressed:
    'inear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #1B1C1A',

  //Interactive Emphasised background
  interactiveEmphasizedBackgroundDefault: '#A9D0B0',
  interactiveEmphasizedBackgroundHover:
    'linear-gradient(0deg, rgba(21, 55, 32, 0.08), rgba(21, 55, 32, 0.08)), #A9D0B0',
  interactiveEmphasizedBackgroundPressed:
    'linear-gradient(0deg, rgba(21, 55, 32, 0.12), rgba(21, 55, 32, 0.12)), #A9D0B0',

  //Interactive Subtle background
  interactiveSubtleBackgroundDefault: '#2E312E',
  interactiveSubtleBackgroundHover:
    ' linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #2E312E',
  interactiveSubtleBackgroundPressed:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #2E312E',

  // Gradient Opacity
  decorationGradientOpacity:
    'linear-gradient(181.09deg, rgba(0, 0, 0, 0) 0.93%, #1B1C1A 74.53%)',

  //Interactive Border
  interactiveEmphasizedBorderSelected: '#A9D0B0',
  interactiveBorderActive: '#9ACBFF',
  interactiveBorderActiveInverse: '#00629E',
  interactiveBorderSecondaryDefault: '#757874',
  interactiveBorderSecondaryHover: '#757874EB',
  interactiveBorderSecondaryPressed: '#757874E0',
  interactiveBorderSecondarySelected: '#757874AD',
  interactiveInverse: '#1B1C1A',

  //Interactive Disabled
  interactiveDisabled_1: '#454744',
  interactiveDisabled_2: '#757874',
};

export default colors;
