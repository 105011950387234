import KexFacet from 'Models/Search/KexFacet.interface';
import SorterModel from 'Models/Search/SorterModel.interface';
import { getFiltersFromUrl } from 'Cms/Pages/SearchPage/FilterComponent/UrlHandler';
import {
  FILTER_URL_CONSTANTS,
  getUrlParameter,
  removeQueryString,
  setUrlParameter,
  setUrlParameters,
} from 'Shared/Common/Helpers';
import { FilterState } from './filterState';

export function toggle<T>(oldSet: Set<T>, key: T): Set<T> {
  const newSet = new Set(oldSet);
  newSet.has(key) ? newSet.delete(key) : newSet.add(key);
  return newSet;
}

export const withMutateUrl = (
  state: FilterState,
  clearFilters = false
): typeof state => {
  if (!state.data && !state.query) {
    if (state.query)
      setUrlParameter(FILTER_URL_CONSTANTS.SEARCH_QUERY, state.query);

    if (clearFilters) {
      removeQueryString();
    }

    return state;
  }

  const prs: [string, string][] = [
    [FILTER_URL_CONSTANTS.SELECTED_MODEL_FILTER, state.selectedModel],
    [FILTER_URL_CONSTANTS.SEARCH_QUERY, state.query],
    ['tab', getUrlParameter('tab')],
  ].filter((s): s is [string, string] => !!s[1]);

  if (state.sorterFilter.value !== 0) {
    prs.push([FILTER_URL_CONSTANTS.ORDER, state.sorterFilter.value.toString()]);
  }

  Array.from(state.multiSelectFilters)
    .filter(([, values]) => values.size > 0)
    .forEach(([key, values]) =>
      prs.push([
        FILTER_URL_CONSTANTS.FILTER + key,
        Array.from(values).join(';'),
      ])
    );

  setUrlParameters(prs);

  return { ...state, queryParams: prs };
};

export const parseDataToFilter = (
  state: FilterState,
  data: { facets: KexFacet[]; sorters: SorterModel[] }
): typeof state => {
  const { sortOrderModel } = getFiltersFromUrl(state.queryParams, data);

  return {
    ...state,
    sorterFilter: sortOrderModel || state.sorterFilter,
    data,
  };
};

export function convertStringToMap(str: string) {
  if (str.startsWith('?')) {
    str = str.substring(1);
  }
  const map = new Map();

  const pairs = str.split('&').filter((pair) => pair.includes('filter'));

  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    const decodedKey = decodeURIComponent(key);

    if (decodedKey.startsWith('filter-')) {
      const filterKey = decodedKey.split('filter-')[1];
      const values = value.split('%3B').map(decodeURIComponent);
      map.set(filterKey, new Set(values));
    }
  });

  return map;
}

interface OrderTranslations {
  '0': string;
  '1': string;
  '2': string;
}

export function createSorterModelFromQueryString(
  queryString: string,
  orderTranslations: OrderTranslations
): SorterModel | null {
  const params = new URLSearchParams(queryString);
  const order = params.get('order');

  if (!order) {
    return null;
  }

  return {
    selected: true,
    text: orderTranslations[order as keyof OrderTranslations] || '',
    value: Number(order),
  };
}

export const toQueryParams = (queryString: string) => {
  return Array.from(new URLSearchParams(queryString));
};
