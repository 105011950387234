import AppTranslations from 'Models/Translations/AppTranslations.interface';
import appInit from 'Stories/Assets/mockAppInitData';
import React from 'react';
import { useAppSettingsData } from './AppSettingsProvider';
import useSWR from 'swr';
import { fetcherSwr } from 'Shared/SWR/fetcherSWR';

type Props = {
  children: React.ReactNode;
  data: AppTranslations;
};

class Translations {
  constructor(private readonly translations: Partial<AppTranslations>) {}

  value<K extends keyof AppTranslations, N extends keyof AppTranslations[K]>(
    apptranslation: K,
    name: N,
    defaultValue?: string
  ) {
    return this.translations[apptranslation]
      ? // eslint-disable-next-line
        (this.translations[apptranslation]![name] as unknown as string)
      : defaultValue ?? '';
  }

  appTranslations<K extends keyof AppTranslations>(
    apptranslation: K,
    defaultValue?: string
  ) {
    const appTransl =
      this.translations[apptranslation] ??
      appInit.mockAppInitData.appSettings.translations[apptranslation];

    return Object.keys(appTransl).reduce<Partial<AppTranslations[K]>>(
      (acc, key) => {
        return {
          ...acc,
          [key]: this.value(
            apptranslation,
            key as keyof AppTranslations[K],
            defaultValue
          ),
        };
      },
      {}
    ) as AppTranslations[K];
  }
}

interface TemporaryAppTranslations extends AppTranslations {
  translations: Translations;
}

const initialState = {} as TemporaryAppTranslations;

const TranslationContext = React.createContext(initialState);

export const TranslationProvider = ({ children, data }: Props) => {
  const { languageRoute } = useAppSettingsData();
  const { data: fetchedTranslations } = useSWR<AppTranslations>(
    `/api/translations/GetTranslations?language=${languageRoute}`,
    fetcherSwr,
    {
      fallbackData: data,
    }
  );

  const providedTranslations = fetchedTranslations ?? data;
  const providerTranslations: TemporaryAppTranslations = {
    ...providedTranslations,
    translations: new Translations(providedTranslations),
  };

  return (
    <TranslationContext.Provider value={providerTranslations}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationData = () => {
  return React.useContext(TranslationContext) as AppTranslations;
};
