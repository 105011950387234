import { useKexLoadingCircle } from 'Kex/KexLoadingCircle';
import HeaderModel from 'Models/Headers/HeaderModel.interface';
import FetchHeader from 'DesignComponents/Organisms/Header/FetchHeader';
import { useEffect } from 'react';
import { IS_PRODUCTION_ENV } from 'Shared/Configs/EnvConfig';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useSWR from 'swr';
import { API_ROOT_PATH } from '../../Shared/Constants/route';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

export default function useQueryHeader(initialData: HeaderModel): HeaderModel {
  const { languageRoute } = useAppSettingsData();
  const [, kexLoadingCircleDispatch] = useKexLoadingCircle();
  const currentPathname =
    canUseDOM() && window.location.pathname
      ? `&currentPathname=${window.location.pathname}`
      : '';

  const requestUrl =
    API_ROOT_PATH +
    `/app/GetStandardHeader?language=${languageRoute}${currentPathname}`;

  const { data: queryData, isValidating } = useSWR(requestUrl, FetchHeader, {
    revalidateOnFocus: IS_PRODUCTION_ENV,
  });

  useEffect(() => {
    kexLoadingCircleDispatch &&
      kexLoadingCircleDispatch({
        type: isValidating && queryData === initialData ? 'add' : 'remove',
        from: 'useQueryHeader',
      });
  }, [isValidating, kexLoadingCircleDispatch, initialData, queryData]);

  return queryData || initialData;
}
