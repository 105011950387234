// Documentation for stiches --> https://stitches.dev/docs/styling
import { createStitches } from '@stitches/react';
import colors from './light.colors';

const { createTheme } = createStitches({});

export type ColorType = keyof typeof light.colors;

const light = createTheme({
  colors,
});

export default light;
