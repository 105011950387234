import KexTerm from 'Models/Search/KexTerm.interface';
import { FilterState } from 'Commerce/Organisms/FilterComponent/FilterReducer/filterState';

const FILTER = 'filter-';
const CONDITION_FILTER = `${FILTER}ProductCondition`;
const ORDER = 'order';

export const getFiltersFromUrl = (
  paramsArray: [string, string][],
  data: NonNullable<FilterState['data']>
) => {
  const params = new Map(paramsArray);

  const multiSelect = (data.facets || [])
    .filter((f) => !!f.terms)
    .map((f): [string, KexTerm[]] => [f.name, f.terms]);

  const outMulti = new Map(
    multiSelect.map(([key]) => {
      const values = (params.get(FILTER + key) || '')
        .split(';')
        .filter((s) => !!s);
      return [key, new Set(values)];
    })
  );

  const conditionFilter = new Set<'New' | 'Used'>();

  params
    .get(CONDITION_FILTER)
    ?.split(',')
    .filter((s): s is 'New' | 'Used' => s === 'New' || s === 'Used')
    .forEach((s) => conditionFilter.add(s));

  const sortOrderModel =
    data.sorters.find(
      (s) => s.value === Number.parseInt(params.get(ORDER) || '')
    ) || data.sorters[0];

  return { outMulti, conditionFilter, sortOrderModel };
};
