import { styled } from 'Theme/stitches.config';
import {
  BaseIconCircleStyle,
  BaseIconCircleStyleBackground,
  BaseIconStyle,
} from '../LoaderBaseStyle';

type PageLoaderType = {
  loading?: boolean;
  fixed?: boolean;
};

const PageLoader = ({ loading, fixed = true }: PageLoaderType) => {
  return (
    <OuterDiv isLoading={loading} fixed={fixed}>
      <InnerDiv isLoading={loading} fixed={fixed}>
        <BaseIconStyle isLoading={loading} viewBox="0 0 48 48">
          <BaseIconCircleStyleBackground
            cx="24"
            cy="24"
            r="20"
            fill="none"
            strokeWidth="5"
          />
          <BaseIconCircleStyle
            isLoading={loading}
            cx="24"
            cy="24"
            r="20"
            fill="none"
            strokeWidth="5"
          />
        </BaseIconStyle>
      </InnerDiv>
    </OuterDiv>
  );
};

const OuterDiv = styled('div', {
  zIndex: '$pageLoader',
  overflow: 'hidden',

  opacity: '0',
  transition: 'opacity $200 cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  pointerEvents: 'none',
  variants: {
    isLoading: {
      true: {
        opacity: 1,
      },
    },
    fixed: {
      true: {
        position: 'fixed',
        w: '100vw',
        h: '100vh',
        top: 0,
        right: 0,
      },
    },
  },
});

const InnerDiv = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 'calc(40%)',
  right: '50%',
  transform: 'translate(50%, -50%)',
  backgroundColor: 'none',
  borderRadius: '50%',
  wh: 48,
  boxShadow: '0px 10px 10px -10px $colors$onSurface',
  transition: 'opacity $200 cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  transitionDelay: '$500',
  opacity: '0',
  variants: {
    isLoading: {
      true: {
        opacity: 1,
      },
    },
    fixed: {
      false: {
        top: '200px',
      },
    },
  },
});

export default PageLoader;
