import { styled } from 'Theme/stitches.config';
import React from 'react';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import LinkModel from 'Models/Link/LinkModel.interface';
import { useLayoutEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'Shared/DOM/WindowHelper';
import { debounce } from 'Shared/Common/debounce';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';

type PropTypes = {
  breadcrumbs: LinkModel[];
  languageRoute: string;
  siteName: string;
};

function Breadcrumb({ breadcrumbs, siteName }: PropTypes) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { innerWidth } = useWindowDimensions();
  const [showTruncatedLinks, setShowTruncatedLinks] = useState(true);
  const [allowScroll, setAllowScroll] = useState(false);
  const isTabletAndLower = useMedia(mediaQueryTypes.bpMax720);

  const {
    commonLabels: { home },
  } = useTranslationData();

  useLayoutEffect(() => {
    !allowScroll && debounce(resize, 200);
  }, [containerRef, innerWidth]);

  const resize = () => {
    if (
      containerRef?.current &&
      (containerRef.current.scrollHeight > containerRef.current.clientHeight ||
        containerRef.current.scrollWidth > containerRef.current.clientWidth)
    ) {
      setShowTruncatedLinks(false);
      setAllowScroll(true);
    } else {
      setShowTruncatedLinks(true);
    }
  };

  // Not visible on Tablet/Mobile according to design in Figma
  if (isTabletAndLower) return <></>;

  return (
    <BreadcrumbDiv
      ref={containerRef}
      allowScroll={allowScroll}
      showTruncatedLinks={showTruncatedLinks}
      aria-label="Breadcrumb"
    >
      <BreadcrumbContainer showTruncatedLinks={showTruncatedLinks}>
        <BreadcrumbLinkWrapper noBorder={true}>
          <InteractableContentLink href={`/`} aria-label={home}>
            {siteName}
          </InteractableContentLink>
          <Separator>/</Separator>
        </BreadcrumbLinkWrapper>

        {breadcrumbs.map((link, index) => {
          const isLastLink = index === breadcrumbs.length - 1;
          const isTruncated = index === breadcrumbs.length - 3;

          return showTruncatedLinks ? (
            <React.Fragment key={index}>
              {!isLastLink ? (
                <BreadcrumbLinkWrapper key={index + link.id}>
                  <InteractableContentLink href={link.href}>
                    {link.text}
                  </InteractableContentLink>
                  {!isLastLink && <Separator>/</Separator>}
                </BreadcrumbLinkWrapper>
              ) : (
                <li>{link.text}</li>
              )}
            </React.Fragment>
          ) : (
            <BreadcrumbLinkWrapper key={index + link.id}>
              {isLastLink ? (
                <>
                  <InteractableContentLink>{link.text}</InteractableContentLink>
                  {!isLastLink && <Separator>/</Separator>}
                </>
              ) : (
                <>
                  {isTruncated && (
                    <>
                      <DotsWrapper
                        onClick={() => {
                          setShowTruncatedLinks(true);
                        }}
                      >
                        ...
                      </DotsWrapper>
                      <Separator>/</Separator>
                    </>
                  )}
                </>
              )}
            </BreadcrumbLinkWrapper>
          );
        })}
      </BreadcrumbContainer>
    </BreadcrumbDiv>
  );
}

const BreadcrumbDiv = styled('nav', {
  h: '100%',
  w: '100%',
  WebkitOverflowScrolling: 'touch',
  overflowX: 'visible',
  alignItems: 'center',
  py: '$s75',
  display: 'flex',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  fontSize: '$fontSize50',
  lineHeight: '$lh16',
  '@bpMin1025': {
    pt: '$s200',
    pb: '$s200',
  },
  variants: {
    allowScroll: {
      true: {},
    },
    showTruncatedLinks: { true: {} },
  },
  compoundVariants: [
    {
      showTruncatedLinks: true,
      allowScroll: true,
      css: {
        '@bpMax720': {
          overflowX: 'scroll',
        },
      },
    },
  ],
});

const BreadcrumbLinkWrapper = styled('li', {
  display: 'inline-flex',
  '& a': {
    borderBottom: '$borders$default solid transparent',
    fontFamily: 'fontBold',
  },
  '&:hover a': {
    borderBottom: '$borders$default solid $onSurface',
  },
  '&:last-child': {
    '& p': {
      color: '$interactiveDisabled_2',
    },
  },

  variants: {
    noBorder: {
      true: {
        '&:hover a': {
          borderBottom: '1px solid transparent',
        },
      },
    },
  },
});

const DotsWrapper = styled('button', {
  cursor: 'pointer',
  fontFamily: 'fontBold',
});

const BreadcrumbContainer = styled('ol', {
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  color: '$onSurface',
  variants: {
    // this fixes so text doesn't hide behind scrollbar
    showTruncatedLinks: {
      true: {
        pr: '$s100',
      },
    },
  },
});

const Separator = styled('span', {
  my: 'auto',
  fs: 6,
  lineHeight: '$lh16',
  pl: '$s50',
  px: 2,
});

export default Breadcrumb;
