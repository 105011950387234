import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';

import { BaseStyle } from './BaseHeadingStyle';
import React, { useMemo } from 'react';
import { SizeTypes, WeightTypes } from 'Shared/Types/types';

type HeadingElementType = Pick<
  JSX.IntrinsicElements,
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
>;

export interface HeadingType {
  children: React.ReactNode;
  tag: keyof HeadingElementType;
  size: SizeTypes;
  weights?: WeightTypes;
  css?: CSS;
  color?: 'Regular' | 'Inverse' | 'OnInteractive';
  id?: string;
}

function Heading({
  children,
  css,
  tag,
  size,
  weights = 'regular',
  color = 'Regular',
  id = '',
}: HeadingType) {
  const Heading = useMemo(() => StyledHeading(tag, css), [tag]);

  const idAttribute = id ? { id } : {};

  return (
    <Heading
      css={css}
      size={size}
      color={color}
      weight={weights}
      {...idAttribute}
    >
      {children}
    </Heading>
  );
}

const StyledHeading = (tag: keyof HeadingElementType, css?: CSS) => {
  return styled(tag, { ...BaseStyle, ...css });
};

export default Heading;
