import { styled } from 'Theme/stitches.config';

import Dots from '../DesignComponents/Atoms/Loaders/Dots';

function KexFallbackBlock() {
  return (
    <StyledFallbackBlock>
      <Dots />
    </StyledFallbackBlock>
  );
}

const StyledFallbackBlock = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  wh: '100%',
});

export default KexFallbackBlock;
