type UserMenuStateType = {
  userMenuIsOpen: boolean;
  query: string;
};

export type UserMenuActionType =
  | { type: 'toggle' }
  | { type: 'setQuery'; query?: string };

const userMenuReducer = (
  state: UserMenuStateType,
  action: UserMenuActionType
): UserMenuStateType => {
  switch (action.type) {
    case 'toggle': {
      return {
        ...state,
        userMenuIsOpen: !state.userMenuIsOpen,
      };
    }
    case 'setQuery':
      if (action.query === undefined) {
        return state;
      }

      return {
        ...state,
        query: action.query,
      };
    default:
      return state;
  }
};

export default userMenuReducer;
