/* tslint:disable:no-empty */
import { ModalContentViews } from 'DesignComponents/Organisms/Modal/ModalContentViews';
import { ModalFooterProps } from 'DesignComponents/Organisms/Modal/ModalFooter';
import {
  useContext,
  createContext,
  useReducer,
  ReactElement,
  useMemo,
} from 'react';
import uiStateReducer from './UiStateReducer';

import ShippingCountryModel from 'Models/Shipping/ShippingCountryModel.interface';

type PropType = {
  children: React.ReactNode;
};

type ModalViewId =
  | keyof typeof ModalContentViews
  | 'ExampleOneStory'
  | 'ExampleTwoStory'
  | 'MiniCartStory';

export interface ModalUiState {
  display: boolean;
  viewId?: ModalViewId;
  openerElement?: HTMLElement;
  modalTitle?: string;
  modalContentComponent?: {
    component?: JSX.Element;
    noContentPadding?: boolean;
  };
  modalFooterComponent?: ReactElement<ModalFooterProps>;
}

export interface QuickSearchUiState {
  isFocused: boolean;
}

interface InitUiStateContextType {
  modalState: ModalUiState;
  quickSearchState: QuickSearchUiState;
  toggleModal: (
    display: boolean,
    viewId?: ModalViewId,
    modalTitle?: string,
    modalContentComponent?: {
      component?: JSX.Element;
      noContentPadding?: boolean;
    },
    modalFooterComponent?: ReactElement<ModalFooterProps>,
    openerElement?: HTMLElement
  ) => void;
  focusQuickSearch: (isFocused: boolean) => void;
  setCountry: (country: ShippingCountryModel) => void;
  country: ShippingCountryModel;
}

export const initUiState: InitUiStateContextType = {
  modalState: {
    display: false,
    viewId: undefined,
    modalTitle: undefined,
    modalContentComponent: undefined,
    modalFooterComponent: undefined,
  },
  quickSearchState: {
    isFocused: false,
  },
  toggleModal: () => undefined,
  focusQuickSearch: () => undefined,
  setCountry: () => undefined,
  country: { code: 'SE', name: 'Sverige' },
};

const UiStateContext = createContext(initUiState);

export const UiStateProvider = ({ children }: PropType) => {
  const [state, dispatch] = useReducer(uiStateReducer, initUiState);

  const focusQuickSearch = (isFocused: boolean) => {
    dispatch({
      type: 'focusQuickSearch',
      payload: {
        quickSearchState: {
          isFocused: isFocused,
        },
      },
    });
  };

  const toggleModal = (
    display: boolean,
    viewId?: ModalViewId,
    modalTitle?: string,
    modalContentComponent?: {
      component?: JSX.Element;
      noContentPadding?: boolean;
    },
    modalFooterComponent?: ReactElement<ModalFooterProps>,
    openerElement?: HTMLElement
  ) => {
    dispatch({
      type: 'toggleModal',
      payload: {
        modalState: {
          display,
          viewId: viewId || undefined,
          modalTitle: modalTitle || undefined,
          modalContentComponent: {
            component: modalContentComponent?.component || undefined,
            noContentPadding:
              modalContentComponent?.noContentPadding || undefined,
          },
          modalFooterComponent: modalFooterComponent || undefined,
          openerElement: openerElement || undefined,
        },
      },
    });
    return undefined;
  };

  const setCountry = (country: ShippingCountryModel) => {
    dispatch({
      type: 'setCountry',
      payload: {
        country: country,
      },
    });
  };

  return (
    <UiStateContext.Provider
      value={useMemo(
        () => ({
          modalState: {
            display: state.modalState.display,
            viewId: state.modalState.viewId,
            modalTitle: state.modalState.modalTitle,
            modalContentComponent: state.modalState.modalContentComponent,
            modalFooterComponent: state.modalState.modalFooterComponent,
            openerElement: state.modalState.openerElement,
          },
          quickSearchState: {
            isFocused: state.quickSearchState.isFocused,
          },
          toggleModal: (
            display,
            viewId,
            modalTitle,
            modalContentComponent,
            modalFooterComponent,
            openerElement
          ) =>
            toggleModal(
              display,
              viewId,
              modalTitle,
              modalContentComponent,
              modalFooterComponent,
              openerElement
            ),
          focusQuickSearch: (isFocused) => focusQuickSearch(isFocused),
          setCountry: (country: ShippingCountryModel) => setCountry(country),
          country: state.country,
        }),
        [state, toggleModal, focusQuickSearch]
      )}
    >
      {children}
    </UiStateContext.Provider>
  );
};

export const useUiState = (): InitUiStateContextType => {
  return useContext(UiStateContext);
};
