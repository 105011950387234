import { styled } from 'Theme/stitches.config';

export const SrOnly = styled('span', {
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  border: '0',
});

export const SrOnlyStyle = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  border: '0',
};

export const FocusVisibleStyle = {
  outlineOffset: '2px !important',
  outline: 'solid 2px $focusBorder  !important',
};

export const FocusVisibleResetStyle = {
  outline: 'none !important',
};

export const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
