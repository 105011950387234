import UserStateModel from 'Models/App/UserStateModel.interface';
import userMenuReducer, {
  UserMenuActionType,
} from 'DesignComponents/Organisms/Header/Reducers/userMenuReducer';
import React, { useReducer } from 'react';
import { FILTER_URL_CONSTANTS, getUrlParameter } from '../Common/Helpers';
import { canUseDOM } from '../DOM/WindowHelper';

import useQueryUserState from '../Hooks/useQueryUserState';

type PropType = {
  children: React.ReactNode;
  data: UserStateModel;
};

type AccountType = {
  user: UserStateModel;
  accountDispatch: React.Dispatch<UserMenuActionType>;
  account: ReturnType<typeof userMenuReducer>;
  reload: () => void;
};

const UserContext = React.createContext<AccountType>({} as AccountType);

export const UserContextProvider = ({ children, data }: PropType) => {
  const [user, reload] = useQueryUserState(data);

  const [account, accountDispatch] = useReducer(userMenuReducer, {
    userMenuIsOpen: false,
    query: canUseDOM()
      ? getUrlParameter(FILTER_URL_CONSTANTS.SEARCH_QUERY)
      : '',
  });

  return (
    <UserContext.Provider
      value={{
        user,
        accountDispatch,
        account,
        reload,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserStateData = () => {
  return React.useContext(UserContext);
};
