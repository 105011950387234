import { Colors, Sizes, Weights } from 'Shared/Types/types';

type HeadingTypes = Sizes & Weights & Colors;

const HeadingVariants: HeadingTypes = {
  color: {
    Regular: {
      color: '$onSurface',
    },
    Inverse: {
      color: '$onInverse',
    },
    OnInteractive: {
      color: '$onInteractivePrimary',
    },
  },
  size: {
    xs: {
      lineHeight: '$lh22',
      fontSize: '$fontSize75',
      fontFamily: 'fontRegular',
      '@bpMin721': {
        lineHeight: '$lh24',
        fontSize: '$fontSize100',
      },
    },
    s: {
      lineHeight: '$lh24',
      fontSize: '$fontSize100',
      fontFamily: 'fontBold',
      '@bpMin721': {
        lineHeight: '$lh26',
        fontSize: '$fontSize200',
      },
    },
    sm: {
      lineHeight: '$lh26',
      fontSize: '$fontSize200',
      fontFamily: 'fontBold',
      '@bpMin721': {
        lineHeight: '$lh26',
        fontSize: '$fontSize200',
      },
    },
    m: {
      lineHeight: '$lh28',
      fontSize: '$fontSize300',
      fontFamily: 'fontBold',
      '@bpMin721': {
        fontSize: '$fontSize500',
        lineHeight: '$lh32',
      },
    },
    l: {
      lineHeight: '$lh32',
      fontSize: '$fontSize500',
      fontFamily: 'fontBold',
      '@bpMin721': {
        lineHeight: '$lh40',
        fontSize: '$fontSize700',
      },
    },
    xl: {
      lineHeight: '$lh40',
      fontSize: '$fontSize700',
      fontFamily: 'fontBold',
      '@bpMin721': {
        fontSize: '$fontSize1000',
        lineHeight: '$lh50',
      },
    },
  },
  weight: {
    semiBold: {
      fontFamily: 'fontSemiBold',
    },
    bold: {
      fontFamily: 'fontBold',
    },
    medium: {},
    regular: {},
  },
};

export const BaseStyle = {
  color: '$onSurface',
  letterSpacing: '0.16px',
  m: 'unset',
  variants: HeadingVariants,
};
