export type ColorType = keyof typeof colors;

const colors = {
  //#region Universal
  white: '#FFFFFF',

  //#region Base - Used for static backgrounds and borders
  surface: '#FFFBF7',
  surfaceVariant: '#EBE4DD',
  decorationBackground: '#DDE5DC',
  decorationSubtle: '#A5ADA4',
  decorationEmphasized: '#757874',
  productCardBackground: '#F2EEE9',
  inverse: '#1B1C1A',
  focusBorder: '#000000',
  linen: '#fbe8e8',
  //#endregion

  //#region Text and icon
  onSurface: '#4D453D',
  onSurfaceSubtle: '#C5C7C2',
  onSurfaceVariant: '#454844',
  onSurfaceDisabled: '#998E82',
  onInteractivePrimary: '#FFFFFF',
  onInteractiveEmphasized: '#3B7772',
  onInteractiveSecondary: '#454744',
  onInverse: '#1B1F1F',
  onSearch: '#B3B3B7',

  //#endregion

  //#region Text blocks
  onBlockHeader: '#4D4141',
  onHeroHeader: '#666157',

  //#region Text Price
  onWasPrice: '#998E82', //'#7E7267',

  //#region Semantic
  semanticError: '#BA1A1A',
  semanticInformative: '#00629E',
  semanticSuccess: '#0B6D36',
  semanticWarning: '#E65100',
  //#endregion

  //#region CTA
  primaryCtaBackgroundDefault: '#4D453D',
  primaryCtaBackgroundDefaultHover: 'rgba(77, 69, 61, 0.9);',
  primaryCtaBackgroundDefaultPressed: 'rgba(77, 69, 61, 0.8);',
  primaryCtaBackgroundInverse: '#FFFFFD',
  primaryCtaBackgroundInverseHover: 'rgba(250, 250, 250, 0.9);',
  primaryCtaBackgroundInversePressed: 'rgba(250, 250, 250, 0.8);',
  //#endregion

  //#region Link
  emphasisedLinkColor: '#3B7772',
  //

  //#region Elevation
  elevateOne: '#FFFFFD',
  overlayOne: '#FFFFFFB3',
  underlayOne: '#00000066',
  //#endregion
  //#region Interactive primary background
  interactivePrimaryBackgroundDefault: '#999182',
  interactivePrimaryBackgroundHover:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #1B1C1A',
  interactivePrimaryBackgroundPressed:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #1B1C1A',
  //#endregion

  //#region Interactive Secondary background
  interactiveSecondaryBackgroundDefault: '#E1E3DE',
  interactiveSecondaryBackgroundHover:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
  interactiveSecondaryBackgroundPressed:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF',
  //#endregion

  //#region Interactive Emphasised background
  interactiveEmphasizedBackgroundDefault: '#43664C',
  interactiveEmphasizedBackgroundHover:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #43664C',
  interactiveEmphasizedBackgroundPressed:
    'linear-gradient(0deg, rgba(27, 28, 26, 0.12), rgba(27, 28, 26, 0.12)), #A9D0B0',
  //#endregion

  //#region Interactive Subtle background
  interactiveBackgroundDefault: '#FAFAFA',
  interactiveSubtleBackgroundDefault: '#F0F1EC',
  interactiveSubtleBackgroundHover: '#F0F1EC',
  interactiveSubtleBackgroundPressed: '#F0F1EC',
  //#endregion

  //#region Static Border
  staticBorderDefault: '#CCC4BC',
  staticBorderSubtle: '#EBE4DD',
  //#endregion

  //#region Interactive Border
  interactiveBorderSeparator: '#EBE4DD',
  interactiveBorder: '#979797',
  interactiveBorderActive: '#576665',
  interactiveBorderEmphasizedSelected: '#43664C',
  interactiveBorderActiveInverse: '#9ACBFF',
  interactiveBorderSecondaryDefault: '#757874',
  interactiveBorderSecondaryHover: '#757874EB',
  interactiveBorderSecondaryPressed: '#3B3935E0',
  interactiveBorderSecondarySelected: '#3B3935C2',
  interactiveInverse: '#E4E2DF',
  //#endregion

  //#region Interactive Notifier
  interactiveNotifierBackgroundDefault: '#3B7772',
  //#endregion

  // Gradient Opacity
  decorationGradientOpacity:
    'linear-gradient(181.09deg, rgba(255, 255, 253, 0) 0.93%, #FFFFFD 74.53%)',

  //#region Interactive Disabled
  interactiveDisabled_1: '#C5C7C2',
  interactiveDisabled_2: '#5C5F5B',
  //#endregion
};

export default colors;
