//New const for stitches and useMedia
//Add when needed
export const mediaQueryTypes = {
  //Layout breakpoints
  bpXL: '(min-width: 1441px)',
  bpL: '(min-width: 1025px) and (max-width: 1440px)',
  bpM: '(min-width: 720px) and (max-width: 1024px)',
  bpS: '(min-width: 376px) and (max-width: 720px)',
  bpXS: '(max-width: 375px)',

  // Common breakpoints 2022-2023
  // Min
  bpMin361: '(min-width: 361px)',
  bpMin376: '(min-width: 376px)',
  bpMin401: '(min-width: 401px)',
  bpMin481: '(min-width: 481px)',
  bpMin601: '(min-width: 601px)',
  bpMin721: '(min-width: 721px)',
  bpMin769: '(min-width: 769px)',
  bpMin860: '(min-width: 860px)',
  bpMin961: '(min-width: 960px)',
  bpMin1025: '(min-width: 1025px)',
  bpMin1150: '(min-width: 1150px)',
  bpMin1201: '(min-width: 1201px)',
  bpMin1281: '(min-width: 1281px)',
  bpMin1441: '(min-width: 1441px)',
  bpMin1921: '(min-width: 1921px)',
  bpMin2561: '(min-width: 2561px)',
  // Max
  bpMax375: '(max-width: 375px)',
  bpMax400: '(max-width: 400px)',
  bpMax480: '(max-width: 480px)',
  bpMax600: '(max-width: 600px)',
  bpMax720: '(max-width: 720px)',
  bpMax768: '(max-width: 768px)',
  bpMax859: '(max-width: 859px)',
  bpMax960: '(max-width: 960px)',
  bpMax1024: '(max-width: 1024px)',
  bpMax1149: '(max-width: 1149px)',
  bpMax1200: '(max-width: 1200px)',
  bpMax1280: '(max-width: 1280px)',
  bpMax1440: '(max-width: 1440px)',
  bpMax1920: '(max-width: 1920px)',
  bpMax2560: '(max-width: 2560px)',
};
