import { styled } from 'Theme/stitches.config';
import { timings } from 'DesignSystem/Animations/animation';
import { animationFrames } from 'DesignSystem/Animations/keyframes';

type DotsType = {
  color?: string;
};

function Dots({ color }: DotsType) {
  return (
    <Wrapper>
      <Dot color={color && color}>.</Dot>
      <Dot color={color && color}>.</Dot>
      <Dot color={color && color}>.</Dot>
    </Wrapper>
  );
}

export default Dots;

const Wrapper = styled('div', {
  h: 'auto',
});

const Dot = styled('div', {
  display: 'inline-block',
  color: '$primaryLight3',
  fs: 32,
  lineHeigh: 'auto',
  animation: `${animationFrames}`,
  animationDuration: timings.sevenFifths,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  animationFillMode: 'both',
  '&:nth-child(2)': {
    animationDelay: timings.oneFifth,
  },
  '&:nth-child(3)': {
    animationDelay: timings.twoFifths,
  },
});
