import React, { Suspense } from 'react';
import KexFallbackBlock from 'Kex/KexFallbackBlock';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

type Props = {
  fallback?: JSX.Element;
  children: React.ReactNode;
};

export function LazyLoad({ fallback, children }: Props) {
  return canUseDOM() ? (
    <Suspense fallback={fallback ? fallback : <KexFallbackBlock />}>
      {children}
    </Suspense>
  ) : (
    <></>
  );
}
