const borders = {
  default: '1px',
  thick: '2px',
  thicker: '3px',
};

const fontSizes = {
  fontSize50: '0.75rem', // 12px
  fontSize75: '0.875rem', // 14px
  fontSize100: '1rem', // 16px
  fontSize200: '1.125rem', // 18px
  fontSize300: '1.25rem', // 20px
  fontSize400: '1.375rem', // 22px
  fontSize500: '1.5rem', // 24px
  fontSize700: '2rem', // 32px
  fontSize1000: '2.625rem', // 42px
  fontSize1200: '3.5rem', // 56px
};

const lineHeights = {
  lh16: '1rem', // 16px
  lh20: '1.25rem', // 20px
  lh22: '1.375rem', // 22px
  lh24: '1.5rem', // 24px
  lh26: '1.625rem', // 26px
  lh28: '1.75rem', // 28px
  lh32: '2rem', // 32px
  lh40: '2.5rem', // 40px
  lh50: '3.125rem', // 50px
  lh64: '4rem', // 64px
};

const radius = {
  rExtraSmall: '4px',
  rSmall: '8px',
  rMedium: '16px',
  rLarge: '32px',
  rMax: '64px',
};

const shadows = {
  ElevationOne: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  OverlayOne: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

const space = {
  s25: '4px',
  s50: '8px',
  s625: '10px',
  s75: '12px',
  s80: '13px',
  s90: '14px',
  s100: '16px',
  s150: '24px',
  s200: '32px',
  s250: '40px',
  s300: '48px',
  s350: '56px',
  s400: '64px',
  s475: '76px',
  s500: '80px',
  s750: '120px',
  s1000: '160px',
};

export default {
  borders,
  fontSizes,
  lineHeights,
  radius,
  shadows,
  space,
};
