import {
  animationRotation,
  slideFromLeftAnimation,
  slideFromRightAnimation,
} from './keyframes';

export const animation = {
  timingFn: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  easeInOut: 'ease-in-out',
  easeOut: 'ease-out',
};

export const timings = {
  oneTenth: '100ms',
  oneFifth: '200ms',
  oneFourth: '250ms',
  threeTenths: '300ms',
  oneThird: '333ms',
  twoFifths: '400ms',
  oneHalf: '500ms',
  threeFifths: '600ms',
  fourFifths: '800ms',
  one: '1000ms',
  sevenFifths: '1400ms',
  oneAndAHalf: '1500ms',
  three: '3000ms',
  five: '5000ms',
  six: '6000ms',
};

export const rollInAnimation = {
  default: {
    opacity: 0,
    transform: 'translateX(-50%) rotate(-180deg)',
    transition: 'all ease-in-out $300',
  },
  hover: {
    opacity: 1,
    transform: 'translateX(50%) rotate(0deg)',
  },
};

export const spinAnimation = {
  default: {
    animation: `${animationRotation}`,
    animationDuration: timings.one,
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
};

export const rotateAnimation = {
  transition: {
    transitionDuration: timings.oneTenth,
    transitionProperty: 'all',
  },
  0: {},
  45: {
    transform: 'rotate(45deg)',
  },
  90: {
    transform: 'rotate(90deg)',
  },
  180: {
    transform: 'rotate(180deg)',
  },
  270: {
    transform: 'rotate(270deg)',
  },
  downRight: {
    transform: 'rotate(315deg)',
  },
};

export const slideAnimation = {
  left: {
    animation: `${slideFromLeftAnimation}`,
    animationDuration: timings.threeTenths,
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  },
  right: {
    animation: `${slideFromRightAnimation}`,
    animationDuration: timings.threeTenths,
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  },
  none: {},
};
