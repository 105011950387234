import { useEffect, useRef } from 'react';
import { styled } from 'Theme/stitches.config';
import { Arrow, Close } from 'DesignSystem/Icons';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';

export interface ModalHeaderContent {
  title?: string;
  stepBackAction?: () => void;
}

type Props = {
  modalHeaderContent?: ModalHeaderContent;
  displayModal: boolean;
  closeModal: (value: boolean, view?: string) => void;
};

const ModalHeader = ({
  displayModal,
  closeModal,
  modalHeaderContent,
}: Props) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const {
    commonLabels: { close },
  } = useTranslationData();

  useEffect(() => {
    if (displayModal) {
      setTimeout(() => {
        closeButtonRef.current?.focus();
      }, 1000);
    }
  }, [displayModal]);

  return (
    <Header>
      {/* Use IconButton when color transparent exist in Button->IconButton */}
      <button ref={closeButtonRef} onClick={() => closeModal(!displayModal)}>
        <Close size="m" color={'primary'} title={close} />
      </button>
      {modalHeaderContent?.title && (
        <Heading tag="h3" size={'m'} css={{ mr: 'auto' }}>
          {modalHeaderContent.title}
        </Heading>
      )}
      {modalHeaderContent?.stepBackAction && (
        // Use IconButton when color transparent exist in Button->IconButton
        <button onClick={() => modalHeaderContent.stepBackAction}>
          <Arrow size="m" color={'primary'} />
        </button>
      )}
    </Header>
  );
};

export default ModalHeader;

const Header = styled('div', {
  backgroundColor: '$surface',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  w: '100%',
  h: 80,
  px: '$s150',
  borderRadius: '8px 8px 0px 0px',
  borderBottom: '$borders$default solid $decorationSubtle',
  '@bpMin961': {
    borderRadius: 0,
  },
});
