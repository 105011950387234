import React, { useEffect, useRef } from 'react';
import { globalCss } from '@stitches/react';
import colors from 'Theme/Light/light.colors';
import { brandLogoWhiteBase64 } from 'Shared/Constants/base64';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
interface QuickNavigatorProps {
  menuTitle: string;
  defaultUrl: string;
  menuItems: any;
}

const QuickNavigator: React.FC<QuickNavigatorProps> = ({
  menuTitle,
  defaultUrl,
  menuItems,
}) => {
  const menuRef = useRef<HTMLUListElement | null>(null);

  GlobalStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (menuRef.current) {
      menuRef.current.style.display = 'block';
    }
  };

  const handleMouseUp = () => {
    if (menuRef.current) {
      menuRef.current.style.display = 'none';
    }
  };

  useEffect(() => {
    if (canUseDOM()) {
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, []);

  return (
    <ul id="epi-quickNavigator">
      <li className="epi-quickNavigator-editLink">
        <a href={defaultUrl}>
          <img src={brandLogoWhiteBase64} width="24" />
          <span>{menuTitle}</span>
        </a>
      </li>
      <li className="epi-quickNavigator-dropdown">
        <a
          className="epi-quickNavigator-dropdown-arrow"
          onClick={handleClick}
        ></a>
        {menuItems && (
          <ul ref={menuRef}>
            <li>
              <a href={menuItems.dashboard.url}>
                {menuItems.dashboard.caption}
              </a>
            </li>
            <li>
              <a href={menuItems.editMode.url}>{menuItems.editMode.caption}</a>
            </li>
            <li>
              <a href={menuItems.commerce.url}>{menuItems.commerce.caption}</a>
            </li>
          </ul>
        )}
      </li>
    </ul>
  );
};

const { white, primaryCtaBackgroundDefault } = colors;

const GlobalStyles = globalCss({
  '#epi-quickNavigator': {
    backgroundColor: primaryCtaBackgroundDefault,
    borderRadius: '4px',
    borderTop: 'none',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    position: 'fixed',
    right: '10px',
    top: '40px',
    zIndex: 99,
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    '& .epi-quickNavigator-editLink': {
      padding: '8px 16px',
    },
    '& .epi-quickNavigator-editLink a span': {
      color: white,
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 400,
      height: '16px',
      paddingLeft: '4px',
      verticalAlign: 'middle',
    },
    '& > li': {
      display: 'block',
      lineHeight: '16px',
      position: 'relative',
    },
    '& .epi-quickNavigator-dropdown-arrow': {
      height: '40px',
      width: '40px',
      borderLeft: '1px solid white',
      borderRadius: '0px 4px 4px 0px',
      justifyContent: 'center',
      background: primaryCtaBackgroundDefault,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgb(49 43 38)',
      },

      '&:after': {
        content: '',
        width: '0',
        height: '0',
        borderStyle: 'solid',
        borderWidth: '0 5px 10px 5px',
        borderColor: 'transparent transparent #fff transparent',
        transform: 'rotate(180deg)',
        display: 'block',
      },
    },

    '& .epi-quickNavigator-dropdown ul': {
      backgroundColor: white,
      borderRadius: '4px',
      display: 'none',
      listStyle: 'none',
      marginTop: '4px',
      padding: '4px',
      position: 'absolute',
      right: '-1px',
      boxShadow: '0 1px 4px rgba(0, 0, 0, 0.25)',
    },
    '& .epi-quickNavigator-dropdown ul li a': {
      color: primaryCtaBackgroundDefault,
      display: 'block',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '1.5rem',
      whiteSpace: 'nowrap',
      padding: '8px 16px',

      '&:hover': {
        backgroundColor: '#f2f2f2',
        color: primaryCtaBackgroundDefault,
      },
    },
  },
});

export default QuickNavigator;
