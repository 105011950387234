import FooterModel from 'Models/Footer/FooterModel.interface';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'Theme/stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { lazy } from 'react';
import { LazyLoad } from 'Shared/Loader/LazyLoader';

type PropTypes = {
  initData: FooterModel;
};

function Footer({ initData }: PropTypes) {
  const isDesktop = useMedia(mediaQueryTypes.bpMin961);

  const { inEditMode } = useCurrentPage<PageModelBase>();
  const DesktopFooter = lazy(
    () =>
      import('DesignComponents/Organisms/Footer/DesktopFooter/DesktopFooter')
  );
  const MobileFooter = lazy(
    () => import('DesignComponents/Organisms/Footer/MobileFooter/MobileFooter')
  );

  return (
    <FooterElm>
      <LowerFooterContainer>
        <LazyLoad>
          {isDesktop || inEditMode ? (
            <DesktopFooter content={initData} />
          ) : (
            <MobileFooter content={initData} />
          )}
        </LazyLoad>
      </LowerFooterContainer>
    </FooterElm>
  );
}

const FooterElm = styled('footer', {
  color: '$onSurface',
  backgroundColor: '$surfaceVariant',
});

const LowerFooterContainer = styled('div', {
  py: '$s300',
  '@bpMin721': {
    pt: '$s300',
    pb: '$s300',
  },
  '@bpMin1025': {
    pt: '$s500',
    pb: '$s300',
  },
});

export default Footer;
