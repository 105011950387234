import { styled } from 'Theme/stitches.config';

type Props = {
  displayModal: boolean;
  closeModal: (value: boolean, view?: string) => void;
};

const ModalOverlay = ({ closeModal, displayModal }: Props) => {
  return (
    <OverlayStyle isVisible={displayModal} onClick={() => closeModal(false)} />
  );
};

export default ModalOverlay;

const OverlayStyle = styled('div', {
  backgroundColor: '$underlayOne',
  tblr: 0,
  position: 'fixed',
  display: 'none',
  zIndex: '$zIndices$ModalOverlay',
  variants: {
    isVisible: {
      true: {
        display: 'block',
      },
    },
  },
});
