import AppInitModel from 'Models/App/AppInitModel.interface';
import { useEffect, useMemo, useRef, useState } from 'react';
import AbortControllerRefType from 'Shared/Common/AbortControllerRefType';
import { IS_PRODUCTION_ENV } from 'Shared/Configs/EnvConfig';
import usePrevious from 'Shared/Hooks/usePrevious';
import useSWR from 'swr';

import { useKexLoadingCircle } from 'Kex/KexLoadingCircle';
import FetchPage, { ReturnPageModel } from './FetchPage';

let hasMounted = false;

export default function useQueryPage(
  url: string,
  initialData: AppInitModel
): ReturnPageModel {
  const [currentPage, setCurrentPage] = useState<ReturnPageModel>(
    initialData.currentPage
  );

  const [, dispatchLoading] = useKexLoadingCircle();
  const abortControllerRef = useRef<AbortControllerRefType>();

  if (abortControllerRef.current && abortControllerRef.current.url !== url) {
    abortControllerRef.current.abortController.abort();
  }

  const args = useMemo(
    () => (url ? [url, abortControllerRef] : null),
    [url, abortControllerRef]
  );

  const options = useMemo(
    () => ({
      fallbackData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }),
    [initialData]
  );

  const { data: fetchedPage, isValidating } = useSWR(args, FetchPage, options);

  const prevFetchedPage = usePrevious<ReturnPageModel | undefined>(fetchedPage);

  useEffect(() => {
    dispatchLoading &&
      dispatchLoading({
        type: isValidating ? 'add' : 'remove',
        from: 'useQueryPage',
      });
  }, [isValidating, dispatchLoading]);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fetchedPage) {
        setCurrentPage(fetchedPage);
      }
    }
  }, [dispatchLoading, fetchedPage, prevFetchedPage]);

  return currentPage;
}
