import GetStandardHeaderModel from 'Models/Headers/GetStandardHeaderModel.interface';
import HeaderModel from 'Models/Headers/HeaderModel.interface';
import Fetcher from 'Shared/Common/Fetcher';

let abortController: AbortController = new AbortController();

function FetchHeader(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const { signal } = abortController;

  return Fetcher<HeaderModel, GetStandardHeaderModel>(
    url,
    signal,
    (res, resolve) => {
      if (res.success) {
        resolve(res.data);
      }
    }
  );
}

export default FetchHeader;
