import HeaderModel from 'Models/Headers/HeaderModel.interface';
import StartPageModel from 'Models/Pages/StartPage/StartPageModel.interface';
import React from 'react';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import useMedia from 'Shared/Hooks/useMedia';
import useQueryHeader from 'Shared/Hooks/useQueryHeader';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import loadable from '@loadable/component';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

const MobileHeader = loadable(
  () => import(/* webpackPrefetch: true */ './MobileHeader/MobileHeader')
);
const DesktopHeader = loadable(
  () => import(/* webpackPrefetch: true */ './DesktopHeader/DesktopHeader')
);

type PropType = {
  initData: HeaderModel;
};

export const HeaderContext = React.createContext({});

function Header({ initData }: PropType) {
  const headerData = useQueryHeader(initData);
  const { inEditMode } = useCurrentPage<StartPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.bpMin721);

  if (canUseDOM()) {
    const { siteLogotype } = headerData ?? {};
    localStorage.setItem('logoUrl', siteLogotype?.src ?? '');
  }

  return (
    <HeaderContext.Provider value={headerData}>
      {isDesktop || inEditMode ? <DesktopHeader /> : <MobileHeader />}
    </HeaderContext.Provider>
  );
}

const useHeaderData = () => {
  return React.useContext(HeaderContext) as HeaderModel;
};

export { Header, useHeaderData };
