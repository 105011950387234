import AppInitModel from 'Models/App/AppInitModel.interface';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

import { updateScrollPosition, useSaveScrollPosition } from './ScrollHandling';
import useQueryPage from './useQueryPage';
import QuickNavigator from 'Shared/QuickNavigator/QuickNavigator';
import { gtmHandlePageEvent } from 'Shared/GTM/GtmPageEventHandler';
import { containsHashtag } from 'Shared/Common/Helpers';

type PropType = {
  appInitData: AppInitModel;
  initUrl: string;
  children: React.ReactNode;
};

export type KexNavigateEventStateType = PopStateEvent & {
  clicked?: true;
  id?: number;
};

export type KexNavigateType = (
  url: string,
  event?: PopStateEvent | undefined,
  clearQueryString?: boolean
) => Promise<PageModelBase>;

type kexNavigateCallbackRefType = {
  resolve: (value: PageModelBase | PromiseLike<PageModelBase>) => void;
  reject: (reason?: any) => void;
  eventState: KexNavigateEventStateType;
};

const KexRouterDispatchContext = React.createContext({});
const KexRouterCurrentPage = React.createContext({});

type RouterState = {
  url: string;
};

type RouterAction = {
  type: 'updateUrl' | 'default';
  url: string;
  clearQueryString?: boolean;
};

const reducer = (state: RouterState, action: RouterAction) => {
  switch (action.type) {
    case 'updateUrl': {
      if (state.url === action.url && canUseDOM() && action.clearQueryString) {
        window.location.href = window.location.pathname;
      }

      return { ...state, url: action.url };
    }
    default: {
      return state;
    }
  }
};
let hasMounted = false;

function KexRouter({ appInitData, initUrl, children }: PropType) {
  const [routerState, dispatchState] = useReducer(reducer, {
    url: initUrl,
  });
  const kexNavigateCallbackRef = useRef<kexNavigateCallbackRefType>();
  const currentPageData = useQueryPage(routerState.url, appInitData);

  const kexNavigate = useCallback(
    (
      url: string,
      event?: PopStateEvent,
      clearQueryString?: boolean
    ): Promise<PageModelBase> => {
      const { state } = event ? event : { state: { clicked: true } };

      const srNotifier = document.getElementById('srNotifier');
      if (srNotifier) {
        srNotifier.innerHTML = 'Laddar sida...';
      }

      return new Promise((resolve, reject) => {
        kexNavigateCallbackRef.current = {
          resolve,
          reject,
          eventState: state,
        };

        dispatchState({
          type: containsHashtag(url) ? 'default' : 'updateUrl',
          url,
          clearQueryString,
        });
      });
    },
    []
  );

  useSaveScrollPosition(kexNavigate);

  useEffect(() => {
    document.title = `${currentPageData.htmlTitle}`;
    /**
     * Trigger page view to GTM.
     */
    if (appInitData?.appSettings?.gtmId) {
      gtmHandlePageEvent(currentPageData);
    }
  }, [currentPageData]);

  /**
   * using useMemo instead of useEffect because we need to updateScrollPosition (change url)
   * before we render the new current page.
   */
  useMemo(() => {
    if (hasMounted) {
      if (kexNavigateCallbackRef.current) {
        if (currentPageData.responseUrl) {
          updateScrollPosition(
            kexNavigateCallbackRef.current.eventState,
            currentPageData.responseUrl,
            currentPageData.pageTitle
          );
          kexNavigateCallbackRef.current = undefined;
        }
      }
    } else {
      hasMounted = true;
    }
    /* we only want to trigger updateScrollPosition the first time we get data from
     * useQueryPage and it's safe to only use pageId as dependency.
     */
  }, [currentPageData.pageId, currentPageData.responseUrl]);

  return (
    <KexRouterCurrentPage.Provider value={currentPageData}>
      <KexRouterDispatchContext.Provider value={kexNavigate}>
        {children}
        {currentPageData?.quickNavigation && (
          <QuickNavigator {...currentPageData.quickNavigation} />
        )}
      </KexRouterDispatchContext.Provider>
    </KexRouterCurrentPage.Provider>
  );
}

const useKexNavigate = () => {
  return React.useContext(KexRouterDispatchContext) as KexNavigateType;
};

function useKexRouterCurrentPage() {
  return React.useContext(KexRouterCurrentPage) as PageModelBase;
}

export { KexRouter, useKexNavigate, useKexRouterCurrentPage };
