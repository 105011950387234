import UserStateModel from 'Models/App/UserStateModel.interface';
import { useEffect, useState } from 'react';
import FetchUserState from 'Shared/Fetchers/FetchUserState';
import useSWR, { mutate } from 'swr';
import { API_ROOT_PATH } from '../../Shared/Constants/route';
import { IS_PRODUCTION_ENV } from '../Configs/EnvConfig';

let hasMounted = false;

export default function useQueryUserState(
  initialData: UserStateModel
): [UserStateModel, () => void] {
  const [data, setData] = useState<UserStateModel>(initialData);
  const queryData = useSWR(
    API_ROOT_PATH + '/app/GetUserState',
    FetchUserState,
    {
      fallbackData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data, queryData.mutate];
}

export async function updateUserState() {
  const res = await fetch(API_ROOT_PATH + '/app/GetUserState');
  if (res.ok) {
    const { userState } = await res.json();
    mutate(API_ROOT_PATH + '/app/GetUserState', userState, false);
  }
}
