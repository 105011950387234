import VariationModel from 'Models/KexVariation/VariationModel.interface';
import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import ArticleProductPageModel from 'Models/Pages/ProductPage/ArticleProductPageModel.interface';
import AppInitModel from 'Models/App/AppInitModel.interface';
import AppSettingsModel from 'Models/App/AppSettingsModel.interface';
import FooterModel from 'Models/Footer/FooterModel.interface';
import HeaderModel from 'Models/Headers/HeaderModel.interface';
import UserStateModel from 'Models/App/UserStateModel.interface';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import ImageModel from 'Models/Assets/ImageModel.interface';
import PriceModel from 'Models/Price/PriceModel.interface';
import SelectableIssue from 'Models/SubscriptionOffer/SelectableIssue.interface';
import CheckoutFormDataModel from 'Models/Checkout/CustomerDetails/CheckoutFormDataModel.interface';
import GoogleTagManagerEvent from 'Models/GoogleTagManager/GoogleTagManagerEvent.interface';
import AddRemoveToCartEventData from 'Models/GoogleTagManager/Events/AddRemoveToCartEventData.interface';
import ShippingCountryModel from 'Models/Shipping/ShippingCountryModel.interface';

export const mockAddress = {} as CheckoutFormDataModel;

export const mockAppSettingsData = {} as AppSettingsModel;

export const mockPageModelBase = {} as PageModelBase;

const priceModel = {
  isFromPrice: true,
  price: 399,
  isZero: false,
  priceAsString: '399',
  priceRoundedAsString: '399',
  priceRoundedWithSymbol: '399 SEK',
  priceWithSymbol: '399 SEK',
  currencySymbol: 'SEK',
};

export const mockVariationModel: VariationModel = {
  additionalInfo: '',
  selectableIssues: [],
  selectedIssue: {} as SelectableIssue,
  code: 'mockVariationModel_code',
  productCode: 'mockCode',
  freight: {} as PriceModel,
  notBuyableReason: '',
  image: {} as ImageModel,
  nameOfAdditionalItems: 'mockVariationModel_nameOfAdditionalItems',
  quantity: 1234,
  variationImages: [],
  isBuyable: false,
  nowPrice: priceModel,
  productType: 'productType',
  stock: {
    inStock: true,
    salesStart: new Date(),
    salesStartFormatted: '3-14-2009',
    stockQuantity: 3,
  },
  wasPrice: priceModel,
  productUrl: 'mockVariationModel_ProductURL',
  premiumMainImage: {} as ImageModel,
  egmontPremiumSuggestedRetailPrice: {} as PriceModel,
  freightAdditionalInfo: '',
  name: '',
  gtmAddRemoveToCartEvent: {} as AddRemoveToCartEventData,
  allowedShippingCountry: {} as ShippingCountryModel,
  shippingNotAllowed: false
};

export const mockRelatedProducts: ProductCardModel[] = [
  {
    brand: 'KEX',
    mainCategory: 'KEX_category',
    code: 'mockRelatedProducts_code',
    name: 'mockRelatedProducts_name',
    url: '',
    image: {} as ImageModel,
    nowPrice: priceModel,
    wasPrice: priceModel,
  },
  {
    brand: 'KEX',
    mainCategory: 'KEX_category',
    code: 'mockRelatedProducts_code2',
    name: 'mockRelatedProducts_name2',
    url: '',
    image: {} as ImageModel,
    nowPrice: priceModel,
    wasPrice: priceModel,
  },
];

export const mockArticleProductPageModel: ArticleProductPageModel = {
  ...mockPageModelBase,
  code: 'mockProductPageModel_code',
  displayName: 'mockProductPageModel_displayName',
  hasLongDescription: true,
  mainCategory: 'mockProductPageModel_mainCategory',
  pageType: 'mockProductPageModel_pageType',
  parentCategoryId: 0,
  parentCategoryName: 'mockProductPageModel_parentCategoryName',
  shortDescription: 'mockProductPageModel_shortDescription',
  variationCollection: [mockVariationModel],
  usp: [],
  contentArea: [],
  globalUsp: [],
  accordions: [],
  mainImage: {} as ImageModel,
  secondaryImage: {} as ImageModel,
  age: '',
  viewItemEvent: {} as GoogleTagManagerEvent
};

export const mockFooter: FooterModel = {
  copyright: 'sample',
  footerLeftHeader: 'JE68',
  footerCenterHeader: 'Genvägar',
  footerRightHeader: 'JE68 Motorcycles AB',
  featuredArea: {
    heading: 'sample',
    text: 'sample',
    facebookLink: {
      href: '/sv/vara-villkor/',
      text: 'Våra villkor',
      target: 'target',
      title: 'title',
      id: 'terms_and_conditions_link_id',
    },
    linkedInLink: {
      href: '/sv/vara-villkor/',
      text: 'Våra villkor',
      target: 'target',
      title: 'title',
      id: 'terms_and_conditions_link_id',
    },
    instagramLink: {
      href: '/sv/vara-villkor/',
      text: 'Våra villkor',
      target: 'target',
      title: 'title',
      id: 'terms_and_conditions_link_id',
    },
  },
  paymentProviders: [],
};

export const mockHeader: HeaderModel = {} as unknown as HeaderModel;

export const mockUserState: UserStateModel = {
  authenticated: true,
  id: 'mockUserStateId',
};

export const mockAppInitData: AppInitModel = {
  appSettings: mockAppSettingsData,
  currentPage: mockArticleProductPageModel,
  footer: mockFooter,
  header: mockHeader,
  initUrl: '',
  userState: mockUserState,
};

const exports = {
  mockAppSettingsData,
  mockPageModelBase,
  mockVariationModel,
  mockRelatedProducts,
  mockArticleProductPageModel,
  mockFooter,
  mockHeader,
  mockUserState,
  mockAppInitData,
};

export default exports;
