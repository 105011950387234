import './imports';

import AppInitModel from 'Models/App/AppInitModel.interface';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { mutate } from 'swr';

import { loadableReady } from '@loadable/component';

import App from './App';
import { useEffect } from 'react';

const state = (window as unknown as { __APP_INIT_DATA__: AppInitModel })
  .__APP_INIT_DATA__ as AppInitModel;

const rootElement: HTMLElement | null = document.getElementById('root');

if (rootElement === null) {
  throw Error('No root element');
}

if (window.history.state === null) {
  window.history.replaceState(
    { id: new Date().valueOf() },
    document.title,
    window.location.href
  );
}

function AppWithCallbackAfterRender() {
  useEffect(() => {
    const siteLoader: HTMLElement | null =
      document.getElementById('site-loader');

    if (siteLoader) {
      const firstChild = siteLoader.firstElementChild;
      const attribute = firstChild?.getAttribute('class');

      if (firstChild && attribute) {
        firstChild.setAttribute(
          'class',
          attribute.replace('site-loader-wrapper', '')
        );
      }
      siteLoader.classList.add('site-loader-remove');
      setTimeout(() => {
        document.body.removeChild(siteLoader);
      }, 700);
    }
  });

  return (
    <>
      <App appInitData={state} />
    </>
  );
}

if (rootElement.children[0]) {
  loadableReady(() => {
    hydrate(<App appInitData={state} />, rootElement);
  });
} else {
  const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
  root.render(<AppWithCallbackAfterRender />);
}

// Listen to the "beta/epiReady" event to update the "context" property.
window.addEventListener('load', () => {
  // Expect "epi" to be there after the "load" event. If it's not then we're
  // not in any editing context.
  let updateTimer: number;
  const { epi } = window as any;
  if (!epi) {
    return;
  }

  function setContext() {
    epi.publish('beta/domUpdated');
    // Subscribe to *all* property updates in on-page edit mode
    epi.subscribe('beta/contentSaved', function (propertyDetails: any) {
      // Ignore update if it wasn't successful, for example if there were validation errors
      if (!propertyDetails.successful) {
        return;
      }
      // fetch new complete data and update whole state.
      clearTimeout(updateTimer);
      updateTimer = window.setTimeout(() => {
        mutate('epiedit');
      }, 500);
    });
  }

  // Check for beta and that ready is an actual true value (not just truthy).
  if (epi.beta && epi.beta.ready === true) {
    // "beta/epiReady" already fired.
    setContext();

    // The subscribe method won't be available in View mode.
  } else if (epi.subscribe) {
    epi.subscribe('beta/epiReady', () => {
      setContext();
    });
  }
});
