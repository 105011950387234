const PRODUCTION_ENV = 'PRODUCTION_ENV';
const DEVELOPMENT_ENV = 'DEVELOPMENT_ENV';

export const ENVIRONMENT =
  process.env.NODE_ENV === 'production' ? PRODUCTION_ENV : DEVELOPMENT_ENV;
export const IS_PRODUCTION_ENV = ENVIRONMENT === PRODUCTION_ENV ? true : false;
export const IS_DEVELOPMENT_ENV =
  ENVIRONMENT === DEVELOPMENT_ENV ? true : false;

export const IS_SERVER_CONTEXT = process.env.REACT_APP_CONTEXT === 'server';
export const IS_CLIENT_CONTEXT = process.env.REACT_APP_CONTEXT === 'client';
