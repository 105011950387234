export const focusStateStyle = {
  '&:focus-visible': {
    outlineOffset: '2px',
    outline: 'solid 2px $focusBorder',
    border: 'unset',
  },
};

export const extraVisibleFocusStateStyle = {
  '&:focus-visible': {
    outlineOffset: '2px',
    outline: 'double 6px $focusBorder  !important',
    boxShadow: '0 0 0 6px white',
    border: 'unset',
  },
};

export const focusStateRoundedStyle = {
  '&:focus-visible': {
    outlineOffset: '2px',
    outline: 'solid 2px $focusBorder',
    borderRadius: '$radius$rLarge',
    border: 'unset',
  },
};
