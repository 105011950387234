import { RefObject } from 'react';
import { canUseDOM } from '../DOM/WindowHelper';
import { EventDispatcher, UPDATE_URL } from './EventDispatcher';

type HasFileExtension = {
  url: string;
};

export const FILTER_URL_CONSTANTS = {
  FILTER: 'filter-',
  SEARCH_QUERY: 'query',
  CONDITION_FILTER: 'filter-ProductCondition',
  SELECTED_MODEL_FILTER: 'selectedModel',
  ITEMS: 'take',
  DEFAULT_ITEMS: 24,
  ORDER: 'order',
  PARENT: 'parent',
};

export const hasFileExtension = ({ url }: HasFileExtension) => {
  //  If the ending part of the url contains minus sign (-) character
  //  we will not consider the url as a file extension
  const copy = url.slice().split('.').pop();
  if (copy && copy.includes('-')) {
    return false;
  }

  return url.indexOf('.') > -1;
};

type IsInternalLinkType = {
  url: string;
};
/*
 * Checks if url is within the domain
 */
export const isInternalLink = ({ url }: IsInternalLinkType) => {
  if (
    (url.charAt(0) === '/' && url.charAt(1) !== '/') ||
    url.charAt(0) === '?' ||
    url.charAt(0) === '#'
  )
    return true;

  const urlPartsQuery = url.split('?');
  if (urlPartsQuery.length > 1) {
    if (urlPartsQuery[0].indexOf(window.location.host) === -1) {
      return false;
    }
    return true;
  }

  const urlPartsHash = urlPartsQuery[0].split('#');
  if (urlPartsHash.length > 1) {
    if (urlPartsHash[0].indexOf(window.location.host) === -1) {
      return false;
    }
    return true;
  }

  if (url.indexOf(window.location.host) !== -1) {
    return true;
  }
  return false;
};

export const getWindow = () =>
  typeof window !== 'undefined' ? window : undefined;

export const getFiveLatestYears = () => {
  const years = [];
  for (let index = 0; index < 5; index++) {
    const currentYear = new Date().getFullYear() - index;
    years.push({
      key: 0,
      value: currentYear.toString(),
    });
  }
  return years;
};
type IsEmailLinkType = {
  url: string;
};
/*
 * Checks if an URL is a mailto link
 */
export const isEmailLink = ({ url }: IsEmailLinkType) => {
  return url.indexOf('mailto:') === 0;
};
/*
 * Parses url sting into parts
 */
export const parseUrl = (url: string) => {
  const matches = url.match(
    /^(([^:/?#]+:)?(?:\/\/((?:([^/?#:]*):([^/?#:]*)@)?([^/?#:]*)(?::([^/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/
  );
  const m = matches === null ? [] : matches;
  //http://username:password@localhost:257/deploy/?asd=asd#asd
  const r = {
    hash: m[10] || '', // #asd
    host: m[3] || '', // localhost:257
    hostname: m[6] || '', // localhost
    href: m[0] || '', // http://username:password@localhost:257/deploy/?asd=asd#asd
    origin: m[1] || '', // http://username:password@localhost:257
    pathname: m[8] || (m[1] ? '/' : ''), // /deploy/
    port: m[7] || '', // 257
    protocol: m[2] || '', // http:
    search: m[9] || '', // ?asd=asd
    username: m[4] || '', // username
    password: m[5] || '', // password
  };
  if (r.protocol.length === 2) {
    r.protocol = 'file:///' + r.protocol.toUpperCase();
    r.origin = r.protocol + '//' + r.host;
  }
  r.href = r.origin + r.pathname + r.search + r.hash;
  return m && r;
};

export const getSearchQueryParameter = () => {
  return getUrlParameter(FILTER_URL_CONSTANTS.SEARCH_QUERY);
};

export const getSelectedModel = () => {
  return getUrlParameter(FILTER_URL_CONSTANTS.SELECTED_MODEL_FILTER);
};

export const getQueryString = (url: string): string => {
  const questionMarkIndex = url.indexOf('?');
  if (questionMarkIndex !== -1) {
    return url.substring(questionMarkIndex + 1);
  }
  return '';
};

/*
 * get value for url parameter
 */
export const getUrlParameter = (name: string, queryString?: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(
    queryString ? queryString : canUseDOM() ? window.location.search : ''
  );
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const setUrlParameters = (params: [string, string][]): string => {
  if (!canUseDOM()) return '';

  const searchParams = new URLSearchParams('');
  params.forEach(([key, value]) => searchParams.set(key, value));

  const paramsString = searchParams.toString();

  window.history.replaceState(
    null,
    '',
    [window.location.pathname, paramsString].filter((x) => !!x).join('?')
  );
  EventDispatcher.dispatch(
    UPDATE_URL,
    [window.location.pathname, paramsString].filter((x) => !!x).join('?')
  );

  return paramsString;
};

export const setUrlParametersWithHistory = (params: [string, string][]) => {
  if (!canUseDOM()) return '';

  const searchParams = new URLSearchParams(window.location.search);
  params.forEach(([key, value]) => searchParams.set(key, value));

  window.history.pushState(null, '', `?${searchParams}`);
  return undefined;
};

export const setUrlParameter = (
  key: string,
  value: string,
  isPageReload = false
) => {
  if (!canUseDOM()) return;

  const searchParams = new URLSearchParams(window.location.search);
  if (value === '') {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  const newRelativePathQuery =
    window.location.pathname + '?' + searchParams.toString();
  window.history.replaceState(null, '', newRelativePathQuery);

  if (isPageReload) {
    window.location.reload();
  }
};

export const removeURLParameter = (parameter: string) => {
  if (!canUseDOM()) return;

  const urlObj = new URL(window.location.href);
  const params = new URLSearchParams(urlObj.search);

  params.delete(parameter);

  urlObj.search = params.toString();
  history.replaceState(null, '', urlObj.toString());
};

export const removeQueryString = () => {
  if (!canUseDOM()) return;

  const urlWithoutQueryString =
    window.location.origin + window.location.pathname;
  window.history.replaceState(null, '', urlWithoutQueryString);
};

export const updateUrl = (pageTitle: string, url: string) => {
  const state = { id: new Date().valueOf() };
  window.history.pushState(state, pageTitle, url);
};

/*
 * used for epi edit mode.
 */
export const applyEditModeAttr = (name: string | false) => {
  return name
    ? {
        'data-epi-property-name': name,
      }
    : {};
};

/**
 * Scroll to function
 * @param {*} elm - element to scroll to - Could be a saved ref.current
 */
let scrollTimeouts: NodeJS.Timeout[] = [];
export const ScrollToEle = (ele: HTMLElement) => {
  if (scrollTimeouts.length) {
    scrollTimeouts.forEach((timeOut: NodeJS.Timeout) => {
      clearTimeout(timeOut);
    });
    scrollTimeouts = [];
  }

  function currentYPosition() {
    if (window.scrollY) return window.scrollY;

    if (document.documentElement && document.documentElement.scrollTop)
      return document.documentElement.scrollTop;

    if (document.body.scrollTop) return document.body.scrollTop;

    return 0;
  }

  function elmYPosition(elm: HTMLElement) {
    const y = elm.offsetTop;
    return y - 30;
  }

  const doScroll = (leapY: number, timer: number, speed: number) =>
    new Promise((resolve) => {
      if (typeof global === 'undefined') return;

      scrollTimeouts.push(
        global.setTimeout(() => {
          window.scrollTo(0, leapY);
          resolve(undefined);
        }, timer * speed)
      );
    });

  return new Promise((resolve) => {
    const startY = currentYPosition();
    const stopY = elmYPosition(ele);
    const distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
      window.scrollTo(0, stopY);
      resolve(undefined);
    }
    let speed = Math.round(distance / 100);
    if (speed >= 20) speed = 20;
    const step = Math.round(distance / 25) < 1 ? 1 : Math.round(distance / 25);
    let leapY = stopY > startY ? startY + step : startY - step;
    let timer = 0;
    const promises = [];
    if (stopY > startY) {
      for (let i = startY; i < stopY; i += step) {
        promises.push(doScroll(leapY, timer, speed));
        leapY += step;
        if (leapY > stopY) leapY = stopY;
        timer++;
      }
      Promise.all(promises).then(() => {
        scrollTimeouts = [];
        resolve(undefined);
      });
      return;
    }
    for (let i = startY; i > stopY; i -= step) {
      promises.push(doScroll(leapY, timer, speed));
      leapY -= step;
      if (leapY < stopY) leapY = stopY;
      timer++;
    }
    Promise.all(promises).then(() => {
      scrollTimeouts = [];
      resolve(undefined);
    });
  });
};

/*
 * A web storage helper, for both sessionStorage and localStorage
 */
type WebStorageHelperType = {
  type: 'sessionStorage' | 'localStorage';
  storageKey: string;
  defaultValue: string | object | boolean;
};
export const webStorageHelper = ({
  type,
  storageKey,
  defaultValue,
}: WebStorageHelperType) => {
  const get = (key?: string) => {
    const item = window[type].getItem(storageKey);
    let data = item ? JSON.parse(item) : null;
    data = data === null ? defaultValue : data;
    return key ? data[key] : data;
  };
  const set = (val: string | object | boolean): void =>
    window[type].setItem(storageKey, JSON.stringify(val));

  if (!window[type].getItem(storageKey)) {
    set(defaultValue);
  }
  return {
    get,
    set,
  };
};

/*
 * Checks if a value is Empty,
 *
 *    Empty Values:
 *                  undefined,
 *                  null,
 *                  '',
 *                  {},
 *                  [],
 *                  NaN
 */
export const isEmpty = (value: unknown) => {
  if (value === undefined) return true;

  if (value === null) return true;

  if (value === '') return true;

  if (typeof value === 'object' && Object.keys(value).length === 0) return true;

  if (Array.isArray(value) && value.length === 0) return true;

  if (Number.isNaN(value)) return true;

  return false;
};

export const truncateString = (value: string, number: number) => {
  if (value.length <= number) {
    return value;
  }
  return value.slice(0, number) + '...';
};

export const convertQueryStringToArray = (
  queryString: string
): [string, string][] => {
  const params = new URLSearchParams(queryString);
  return Array.from(params.entries()).map(([key, value]) => [
    key,
    decodeURIComponent(value.replace('%2B', ' ')),
  ]);
};

export const scrollToElementByRef = (elementRef: RefObject<HTMLDivElement>) => {
  elementRef &&
    elementRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
};

export const isSvgFile = (filePath: string): boolean => {
  const svgRegex = /\.svg$/i;
  return svgRegex.test(filePath);
};

export const containsHashtag = (url: string): boolean => {
  return url.includes('#');
};
