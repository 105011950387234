import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Close({ size, color, css, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      css={css}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <BaseIconPathStyle
        d="M12.9372 11.9999L17.7972 7.13993C17.9064 7.0124 17.9635 6.84835 17.957 6.68056C17.9505 6.51278 17.881 6.35362 17.7623 6.23489C17.6435 6.11616 17.4844 6.0466 17.3166 6.04012C17.1488 6.03364 16.9848 6.09071 16.8572 6.19993L11.9972 11.0599L7.13722 6.19326C7.00968 6.08405 6.84563 6.02698 6.67785 6.03346C6.51006 6.03994 6.3509 6.10949 6.23217 6.22822C6.11344 6.34695 6.04389 6.50611 6.03741 6.67389C6.03093 6.84168 6.088 7.00573 6.19722 7.13326L11.0572 11.9999L6.19055 16.8599C6.12076 16.9197 6.06408 16.9932 6.02407 17.076C5.98405 17.1587 5.96156 17.2487 5.95802 17.3406C5.95447 17.4324 5.96994 17.5239 6.00346 17.6095C6.03697 17.695 6.08781 17.7727 6.15278 17.8377C6.21775 17.9027 6.29545 17.9535 6.381 17.987C6.46655 18.0205 6.55811 18.036 6.64992 18.0325C6.74173 18.0289 6.83182 18.0064 6.91453 17.9664C6.99724 17.9264 7.07078 17.8697 7.13055 17.7999L11.9972 12.9399L16.8572 17.7999C16.9848 17.9091 17.1488 17.9662 17.3166 17.9597C17.4844 17.9533 17.6435 17.8837 17.7623 17.765C17.881 17.6462 17.9505 17.4871 17.957 17.3193C17.9635 17.1515 17.9064 16.9875 17.7972 16.8599L12.9372 11.9999Z"
        fill={color}
        strokeWidth="1"
      />
    </BaseSvgStyle>
  );
}

export default Close;
