import AppInitModel from 'Models/App/AppInitModel.interface';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import { Helmet } from 'react-helmet-async';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

function PageMetaData() {
  const {
    alternativeLinks,
    canonicalLink,
    disableIndexing,
    metaDescription,
    openGraphDescription,
    openGraphImageUrl,
    openGraphTitle,
    openGraphType,
    htmlTitle,
  } = useCurrentPage<PageModelBase>();
  return (
    <Helmet prioritizeSeoTags>
      <title>{htmlTitle}</title>
      {Object.keys(alternativeLinks).map((lang, idx) => (
        <link
          key={idx}
          rel="alternate"
          href={alternativeLinks[lang]}
          hrefLang={lang}
          data-rh="true"
        />
      ))}
      {canonicalLink && (
        <link rel="canonical" href={canonicalLink} data-rh="true" />
      )}
      {metaDescription && (
        <meta name="description" content={metaDescription} data-rh="true" />
      )}
      {openGraphTitle && (
        <meta property="og:title" content={openGraphTitle} data-rh="true" />
      )}
      {openGraphType && (
        <meta property="og:type" content={openGraphType} data-rh="true" />
      )}
      {canonicalLink && (
        <meta property="og:url" content={canonicalLink} data-rh="true" />
      )}
      {openGraphDescription && (
        <meta
          property="og:description"
          content={openGraphDescription}
          data-rh="true"
        />
      )}
      {openGraphImageUrl && (
        <meta property="og:image" content={openGraphImageUrl} data-rh="true" />
      )}
      {disableIndexing && (
        <meta name="robots" content="noindex, nofollow" data-rh="true" />
      )}
    </Helmet>
  );
}

export const SSRMetaData = (appInitData: AppInitModel) => {
  const alternateLinks = appInitData?.currentPage?.alternativeLinks
    ? Object.keys(appInitData.currentPage.alternativeLinks).reduce(
        (previousValue, key: string) => {
          return `${previousValue} <link rel="alternate" href="${appInitData.currentPage.alternativeLinks[key]}" hreflang="${key}" /> \n`;
        },
        ''
      )
    : '';

  const pageTitle = appInitData?.currentPage?.htmlTitle
    ? `<title>${appInitData.currentPage.htmlTitle}</title data-rh="true">`
    : '';
  const canonicalLink = appInitData?.currentPage?.canonicalLink
    ? `<link rel="canonical" href="${appInitData.currentPage.canonicalLink}" data-rh="true">`
    : '';
  const description = appInitData?.currentPage?.metaDescription
    ? `<meta name="description" content="${appInitData.currentPage.metaDescription}" data-rh="true">`
    : '';
  const ogTitle = appInitData?.currentPage?.openGraphTitle
    ? `<meta property="og:title" content="${appInitData.currentPage.openGraphTitle}" data-rh="true" data-rh="true">`
    : '';
  const ogType = appInitData?.currentPage?.openGraphType
    ? `<meta property="og:title" content="${appInitData.currentPage.openGraphType}" data-rh="true" data-rh="true">`
    : '';
  const ogDescription = appInitData?.currentPage?.openGraphDescription
    ? `<meta property="og:description" content="${appInitData.currentPage.openGraphDescription}" data-rh="true">`
    : '';
  const ogUrl = appInitData?.currentPage?.openGraphUrl
    ? `<meta property="og:url" content="${appInitData.currentPage.openGraphUrl}" data-rh="true">`
    : '';
  const ogImage = appInitData?.currentPage?.openGraphImageUrl
    ? `<meta property="og:image" content="${appInitData.currentPage.openGraphImageUrl}" data-rh="true">`
    : '';
  const disableIndex = appInitData?.currentPage?.disableIndexing
    ? `<meta name="robots" content="noindex, nofollow" data-rh="true">`
    : '';
  return `${disableIndex}${pageTitle}
  ${alternateLinks}
  ${canonicalLink}
  ${description}
  ${ogTitle}
  ${ogDescription}
  ${ogUrl}
  ${ogType}
  ${ogImage}`;
};

export default PageMetaData;
