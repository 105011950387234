import { ColorType } from '../Dark/dark.colors';

const FS_MULTIPLIER = 2;
const MULTIPLIER = 4;

export const utils = {
  c: (value: ColorType) => ({
    color: '$' + value,
  }),
  p: (value: number | string) => ({
    padding: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  pb: (value: number | string) => ({
    paddingBottom: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  pt: (value: number | string) => ({
    paddingTop: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  pl: (value: number | string) => ({
    paddingLeft: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  pr: (value: number | string) => ({
    paddingRight: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  py: (value: number | string) => ({
    paddingTop: typeof value === 'string' ? value : value * MULTIPLIER,
    paddingBottom: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  px: (value: number | string) => ({
    paddingLeft: typeof value === 'string' ? value : value * MULTIPLIER,
    paddingRight: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  m: (value: number | string) => ({
    margin: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  my: (value: number | string) => ({
    marginTop: typeof value === 'string' ? value : value * MULTIPLIER,
    marginBottom: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  mx: (value: number | string) => ({
    marginLeft: typeof value === 'string' ? value : value * MULTIPLIER,
    marginRight: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  mt: (value: number | string) => ({
    marginTop: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  mb: (value: number | string) => ({
    marginBottom: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  mr: (value: number | string) => ({
    marginRight: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  ml: (value: number | string) => ({
    marginLeft: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  g: (value: number | string) => ({
    gap: value,
  }),
  b: (value: number | string) => ({
    bottom: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  t: (value: number | string) => ({
    top: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  l: (value: number | string) => ({
    left: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  r: (value: number | string) => ({
    right: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  tblr: (value: number | string) => ({
    top: typeof value === 'string' ? value : value * MULTIPLIER,
    bottom: typeof value === 'string' ? value : value * MULTIPLIER,
    left: typeof value === 'string' ? value : value * MULTIPLIER,
    right: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  br: (value: number | string) => ({
    borderRadius: typeof value === 'string' ? value : value * MULTIPLIER,
  }),
  wh: (value: number | string) => ({
    width: value,
    height: value,
  }),
  w: (value: number | string) => ({
    width: value,
  }),
  h: (value: number | string) => ({
    height: value,
  }),
  minH: (value: number | string) => ({
    minHeight: value,
  }),
  maxH: (value: number | string) => ({
    maxHeight: value,
  }),
  minW: (value: number | string) => ({
    minWidth: value,
  }),
  maxW: (value: number | string) => ({
    maxWidth: value,
  }),
  fs: (value: number) => ({
    fontSize: value * FS_MULTIPLIER,
  }),
  ls: (value: string) => ({
    letterSpacing: value,
  }),
};
