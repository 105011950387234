import { useState, useEffect } from 'react';

import { IS_SERVER_CONTEXT } from '../Configs/EnvConfig';
import { ValueOf } from 'Shared/Types/types';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { getWindow } from 'Shared/Common/Helpers';

function useMedia(query: string, initialServerValue = false) {
  const win = getWindow();
  const [matches, setMatches] = useState(() =>
    IS_SERVER_CONTEXT
      ? initialServerValue
      : win?.matchMedia(query).matches ?? true
  );

  useEffect(() => {
    if (win === undefined) {
      setMatches(true);
      return;
    }

    const media = win.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);

    win.addEventListener('resize', listener);

    return () => win.removeEventListener('resize', listener); // eslint-disable-line consistent-return
  }, [matches, query]);

  return matches;
}
export const useIsBetweenSpan = (
  min: ValueOf<typeof mediaQueryTypes>,
  max: ValueOf<typeof mediaQueryTypes>,
  initialServerValue = false
) => {
  const win = getWindow();
  const [matches, setMatches] = useState(() =>
    IS_SERVER_CONTEXT
      ? initialServerValue
      : win?.matchMedia(`${min} and ${max}`).matches ?? true
  );

  useEffect(() => {
    if (win === undefined) {
      setMatches(true);
      return;
    }

    const media = win.matchMedia(`${min} and ${max}`);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);

    win.addEventListener('resize', listener);

    return () => win.removeEventListener('resize', listener); // eslint-disable-line consistent-return
  }, [matches, min, max]);

  return matches;
};

export default useMedia;

export const useExtraSmallDevice = () => useMedia(mediaQueryTypes.bpMax375);
export const useSmallDevice = () =>
  useIsBetweenSpan(mediaQueryTypes.bpMin376, mediaQueryTypes.bpMax720);

export const useMediumDevice = () =>
  useIsBetweenSpan(mediaQueryTypes.bpMin721, mediaQueryTypes.bpMax1024);
export const useLargeDevice = () =>
  useIsBetweenSpan(mediaQueryTypes.bpMin1025, mediaQueryTypes.bpMax1440);
export const useExtraLargeDevice = () => useMedia(mediaQueryTypes.bpMin1441);
