import { StitchesVariantProps } from 'Shared/Types/types';
import { styled } from 'Theme/stitches.config';
import { IconBaseTypeColor } from './IconBaseType';

export const sizeVariations = {
  size: {
    s: {
      wh: 16,
    },
    m: {
      wh: 24,
    },
    l: {
      wh: 32,
    },
    logo: {
      w: 85,
      h: 19,
    },
    logoDinTidning: {
      w: 132,
    },
    logoDinTidningSmall: {
      w: 100,
    },
    logoMitBlad: {
      w: 97,
      h: 20,
    },
  },
};

export const iconColors = {
  primary: '$onSurface',
  inverse: '$onInverse',
  onInteractiveSecondary: '$onInteractiveSecondary',
  onInteractiveEmphasized: '$onInteractiveEmphasized',
  transparent: 'transparent',
};

const getColors = (prop: string) =>
  Object.keys(iconColors).reduce<Record<string, Record<string, string>>>(
    (acc, key) => {
      acc[key as IconBaseTypeColor] = {
        [prop]: iconColors[key as IconBaseTypeColor],
      };
      return acc;
    },
    {}
  ) as Record<IconBaseTypeColor, Record<string, string>>;

const colorVariations = {
  stroke: getColors('stroke'),
  fill: getColors('fill'),
};

const pathVariations = {
  strokeRound: {
    true: {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  },
};

type StitchesVariants = StitchesVariantProps<typeof BaseSvgStyle>;
export type IconColor = StitchesVariants['stroke'];
export const BaseSvgStyle = styled('svg', {
  fill: 'transparent',
  variants: {
    ...sizeVariations,
    ...colorVariations,
  },
});
export const BaseIconPathStyle = styled('path', {
  strokeWidth: '1.5',
  variants: { ...colorVariations, ...pathVariations },
});
export const BaseIconRectStyle = styled('rect', {
  variants: { ...colorVariations },
});
export const BaseIconCircleStyle = styled('circle', {
  variants: { ...colorVariations },
});
